import customers from '@/api/customers';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import nf from '@/constants/nf';

export const getCustomerByOrder = createAsyncThunk('cart/setCustomer', async (params) => {
	const { getCustomers } = customers();
	const response = await getCustomers(params.params, params.id);
	return response.data;
});

export const generateDiscountValue = (item, isVoidType) => {
	const discount = item.discount;
	const discCondition = getDiscountCondition(discount);

	let totalAddOns = 0
	if (item.add_ons?.length) {
		totalAddOns = item.add_ons.reduce((a, b) => +a + (+b.add_on?.price || +b.price || 0), 0)
	}
    const itemPrice = ('unit_price' in item ? +item.unit_price : 'price' in item ? +item.price : 0) + totalAddOns

	let val = 0;
	let disc = 0;
	if (discCondition === 'percentage') {
		disc = itemPrice * (+discount.value / 100);
		val = disc * item.quantity;
		val = val > 0 ? `${isVoidType ? '' : '-'}${nf(val)}` : 0;
	}
	if (discCondition === 'fixed price') {
		if (itemPrice > +discount.value) {
			disc = +discount.value - itemPrice;
			val = `${nf(disc * item.quantity)}`;
			disc = disc * (isVoidType ? 1 : -1);
		}
	}
	if (discCondition === 'fixed amount') {
		disc = +discount.value;
		val = `${nf(disc * item.quantity * (isVoidType ? 1 : -1))}`;
	}
	return { val, disc };
};

export const getDiscountCondition = (itm) => {
	let discount = '';
	if (itm?.type?.toLowerCase().includes('percentage')) {
		discount = 'percentage';
	} else if (itm?.type?.toLowerCase().includes('fixed') && itm?.type?.toLowerCase().includes('price')) {
		discount = 'fixed price';
	} else if (itm?.type?.toLowerCase().includes('fixed') && itm?.type?.toLowerCase().includes('amount')) {
		discount = 'fixed amount';
	}

	return discount;
};

export const getDiscountItem = (price, amount, discount) => {
	let discValue = 0;
	const discCondition = getDiscountCondition(discount);
	if (discCondition === 'percentage') {
		discValue = (price * (+discount.value / 100) * amount);
	} else if (discCondition === 'fixed price') {
		discValue = (+price - +discount.value) * amount;
	} else if (discCondition === 'fixed amount') {
		discValue = +discount.value * amount
	}

	return discValue
}

export const getDiscountValue = (disc, filteredItem, subTWithNoDiscInItm) => {
	let discValue = 0;
	const discCondition = getDiscountCondition(disc);

	if (discCondition === 'percentage') {
		discValue = subTWithNoDiscInItm * (+disc.value / 100);
	} else if (discCondition === 'Voucher') {
		discValue = +disc.value;
	} else if (discCondition === 'fixed price') {
		discValue = filteredItem
			.filter((itm) => !itm.discount)
			.map((itm) => {
				let deductedPrice = +itm.price < +disc.value ? 0 : +itm.price - +disc.value;
				let val = +deductedPrice * +itm.quantity;
				return val;
			})
			.reduce((a, b) => a + b, 0);
	} else if (discCondition === 'fixed amount') {
		discValue = filteredItem
			.filter((itm) => !itm.discount)
			.map((itm) => {
				let val = +disc.value * +itm.quantity;
				return val;
			})
			.reduce((a, b) => a + b, 0);
	}
	return discValue;
};

export const getSubTotal = (filteredItem, currDisc) => {
	let totalModifiers = [];
	let totalItemWithInlineDiscount = [];
	const subT = filteredItem
		.map((itm) => {
			let totalAddOns = 0
			if (itm.add_ons?.length) {
				const priceModifiers = itm.add_ons.map((i) => (+i.add_on?.price || +i?.price || 0) * +itm.quantity).reduce((a, b) => a + b, 0);
				totalModifiers.push(priceModifiers);

				totalAddOns = itm.add_ons.reduce((a, b) => +a + (+b.add_on?.price || +b?.price || 0), 0)
			}

			// let price = 'price' in itm ? +itm.price : 'unit_price' in itm ? +itm.unit_price : 0;
			let price = ('unit_price' in itm ? +itm.unit_price : 'price' in itm ? +itm.price : 0) + totalAddOns;
			// if price value below discount value
			if (currDisc && currDisc.type === 'Fixed' && price < +currDisc.value) {
				// price = +currDisc.value;
			}

			// if item have inline discount
			if (itm.discount) {
				const discCondition = getDiscountCondition(itm.discount);
				if (discCondition === 'percentage') {
					const discItemVal = (+itm.discount.value * +price) / 100;
					const itmPrice = +price - discItemVal;

					const val = itmPrice * +itm.quantity;
					totalItemWithInlineDiscount.push({ value: val, discValue: discItemVal * +itm.quantity });
				}

				if (discCondition === 'fixed price') {
					const discItemVal = +price - +itm.discount.value;
					const itmPrice = +price < +itm.discount.value ? 0 : discItemVal;
					const val = itmPrice * +itm.quantity;

					totalItemWithInlineDiscount.push({ value: val, discValue: discItemVal * +itm.quantity });
				}

				if (discCondition === 'fixed amount') {
					const discItemVal = +itm.discount.value;
					const itmPrice = +price - discItemVal;
					const val = itmPrice * +itm.quantity;

					totalItemWithInlineDiscount.push({ value: val, discValue: discItemVal * +itm.quantity });
				}
			}

			return (price - totalAddOns) * +itm.quantity;
		})
		.reduce((a, b) => a + b, 0);
	return { subT, totalModifiers, totalItemWithInlineDiscount };
};

export const getDiscountCustomer = createAsyncThunk('content/setDiscountCustomer', async () => {
	const { getDiscountCustomer } = customers();
	const response = await getDiscountCustomer();
	return response.data;
});

export const getEverything = (currItm, currDisc, subDiscounts) => {
	// const filteredItem = currItm.filter((i) => !i.is_void);
	const filteredItem = currItm;
	const filteredItemWithNoDisc = filteredItem.filter((i) => !i.discount);
	const filteredItemVoidAlready = currItm.filter((i) => i.is_void && i.void_order)
	const filteredItemVoid = currItm.filter((i) => i.is_void && !i.void_order)

	const { subT, totalModifiers, totalItemWithInlineDiscount } = getSubTotal(filteredItem, currDisc);
	const { subT: subTVA, totalModifiers: totalModifiersVA, totalItemWithInlineDiscount: totalItemWithInlineDiscountVA } = getSubTotal(filteredItemVoidAlready);
	const { subT: subTV, totalModifiers: totalModifiersV, totalItemWithInlineDiscount: totalItemWithInlineDiscountV } = getSubTotal(filteredItemVoid);

	const valInlineDiscount = totalItemWithInlineDiscount.length ? totalItemWithInlineDiscount.reduce((a, b) => a + b.discValue, 0) : 0;
	const valInlineDiscountVA = totalItemWithInlineDiscountVA.length ? totalItemWithInlineDiscountVA.reduce((a, b) => a + b.discValue, 0) : 0;
	const valInlineDiscountV = totalItemWithInlineDiscountV.length ? totalItemWithInlineDiscountV.reduce((a, b) => a + b.discValue, 0) : 0;
	const subTWithNoDiscInItm = filteredItemWithNoDisc.reduce((a, b) => a + (+b.price || 0) * b.quantity, 0);

	let T = subT;
	let subDisc = subDiscounts;
	if (currDisc) {
		const discValue = getDiscountValue(currDisc, filteredItem, subTWithNoDiscInItm);

		subDisc = discValue;
	}
	const valModifiers = totalModifiers.length ? totalModifiers.reduce((a, b) => a + b, 0) : 0;
	const totalVoidA = subTVA + (totalModifiersVA?.length ? totalModifiersVA.reduce((a, b) => a + b, 0) : 0) - valInlineDiscountVA;
	const totalVoid = subTV + (totalModifiersV?.length ? totalModifiersV.reduce((a, b) => a + b, 0) : 0) - valInlineDiscountV;

	return { valInlineDiscount, T, subT, subDisc, valModifiers, subTWithNoDiscInItm, totalVoid, totalVoidA };
};

const cartSlice = createSlice({
	name: 'cart',
	initialState: {
		customer: null,
		items: [],
		subTotal: 0,
		subItemTotal: 0,
		totalAddOns: 0,
		total: 0,
		discount: 0,
		subDiscounts: 0,
		subItemDiscounts: 0,
		tax: [],
		notes: '',
		payment: '',
		order: null,
		historyCollection: [],
		categoryFilter: null,

		openOrderConfirmation: false,
		openPayment: false,
		orderDate: dayjs(),
		selectedItem: null,
	},
	reducers: {
		setItems(state, action) {
			const currState = state;
			const data = action.payload;
			const currItm = currState.items;
			const currDisc = currState.discount;
			const subDiscounts = currState.subDiscounts;

			let newItm = data;
			if (currDisc) {
				newItm.discount = currDisc
			}
			const isItmExist = currItm.findIndex((i) => {
				const isId = i.uid === newItm.uid && i.serving_time.id === newItm.serving_time.id;
				if (!isId) {
					return false;
				}
				let isAddOns = true;
				let isModifiers = true;
				if (i.add_ons?.length) {
					isAddOns = i.add_ons.every((a) => newItm.add_ons.find((n) => n.id === a.id));
				}
				if (i.modifiers?.length) {
					isModifiers = i.modifiers.every((a) => newItm.modifiers.find((n) => n.id === a.id));
				}
				return isId && isAddOns && isModifiers;
			});

			if (isItmExist !== -1) {
				currItm[isItmExist].quantity = +currItm[isItmExist].quantity + +data.quantity;
			} else {
				currItm.push(newItm);
			}

			const { valInlineDiscount, T, subT, subDisc, valModifiers, subTWithNoDiscInItm } = getEverything(currItm, currDisc, subDiscounts);

			currState.items = currItm;
			currState.subItemTotal = subTWithNoDiscInItm;
			currState.subItemDiscounts = valInlineDiscount;
			currState.subDiscounts = subDisc;
			currState.subTotal = subT;
			currState.total = T - (valInlineDiscount + subDisc);
			currState.totalAddOns = valModifiers;
			toast.success(`Item added to cart`);
		},
		updateItem(state, action) {
			const currState = state;
			const data = action.payload;
			const currItm = currState.items;
			const currDisc = currState.discount;
			const subDiscounts = currState.subDiscounts;

			const isItemExist = currItm.findIndex((i) => i.uid === data.uid);
			if (isItemExist !== -1) {
				currItm[isItemExist] = data;
			}

			const { valInlineDiscount, T, subT, subDisc, valModifiers, subTWithNoDiscInItm } = getEverything(currItm, currDisc, subDiscounts);

			currState.items = currItm;
			currState.subItemTotal = subTWithNoDiscInItm;
			currState.subTotal = subT;
			currState.total = T - (valInlineDiscount + subDisc);
			currState.totalAddOns = valModifiers;
		},
		removeItem(state, action) {
			const currState = state;
			const currItm = currState.items;
			const data = action.payload;

			const isItemExist = currItm.findIndex((i) => i.uid === data);
			if (isItemExist !== -1) {
				currItm.splice(isItemExist, 1);
				currState.items = currItm;
			}
		},
		setVoidItem(state, action) {
			const currState = state;
			const data = action.payload;
			const currItm = currState.items;
			const currDisc = currState.discount;
			const subDiscounts = currState.subDiscounts;

			const isItemExist = currItm.findIndex((i) => i.uid === data && !i.is_void);
			if (isItemExist !== -1) {
				currItm[isItemExist].is_void = !currItm[isItemExist].is_void;

				const { valInlineDiscount, T, subT, subDisc, valModifiers, subTWithNoDiscInItm } = getEverything(currItm, currDisc, subDiscounts);

				currState.items = currItm;
				currState.subItemTotal = subTWithNoDiscInItm;
				currState.subItemDiscounts = valInlineDiscount;
				currState.subDiscounts = subDisc;
				currState.subTotal = subT;
				currState.total = T - (valInlineDiscount + subDisc);
				currState.totalAddOns = valModifiers;
			}
		},
		setItemQuantity(state, action) {
			const currState = state;
			const currItm = currState.items;
			const currDisc = currState.discount;
			const subDiscounts = currState.subDiscounts;
			const data = action.payload;
			const { item, type } = data;

			const isItmExist = currItm.findIndex((i) => i.uid === item.uid);
			if (isItmExist !== -1) {
				if (type === 'plus') {
					currItm[isItmExist].quantity += 1;
				} else if (type === 'minus') {
					if (currItm[isItmExist].quantity > 1) {
						currItm[isItmExist].quantity -= 1;
					} else {
						currItm.splice(isItmExist, 1);
					}
				}
			}

			const { valInlineDiscount, T, subT, subDisc, valModifiers, subTWithNoDiscInItm } = getEverything(currItm, currDisc, subDiscounts);

			currState.items = currItm;
			currState.subItemTotal = subTWithNoDiscInItm;
			currState.subTotal = subT;
			currState.total = T - (valInlineDiscount + subDisc);
			currState.totalAddOns = valModifiers;
		},
		setDiscount(state, action) {
			const currState = state;
			const data = action.payload;
			const currItm = currState.items;
			const currSubT = currState.subTotal;
			const currSubIT = currState.subItemTotal;
			const currT = currState.total;
			const currS = currState.subDiscounts;
			const currSID = currState.subItemDiscounts;

			if (!data) {
				currState.subTotal = currSubT;
				currState.total = currT + currS;

				currState.discount = null;
				currState.subDiscounts = 0;
				return;
			}
			const currTotal = currState.subTotal;

			const filteredItem = currItm.filter((i) => !i.is_void);

			const discValue = getDiscountValue(data, filteredItem, currSubIT);

			currState.total = currTotal - (currSID + discValue);
			currState.discount = data;
			currState.subDiscounts = discValue;
		},
		setTax(state, action) {
			const currState = state;
			const data = action.payload;
			currState.tax = data;
		},
		setNotes(state, action) {
			const currState = state;
			const data = action.payload;
			const { date, notes } = data;
			let currItm = currState.items;

			const isItmExist = currItm.filter((c) => c.date === date);
			if (isItmExist.length > 0) {
				currItm = currItm.map((c) => {
					const obj = { ...c };
					if (c.date === date) {
						obj.notes = notes;
					}
					return obj;
				});
			}

			currState.items = currItm;
		},
		setPayment(state, action) {
			const currState = state;
			const data = action.payload;
			currState.payment = data;
		},
		resetItems(state, action) {
			const currState = state;
			const data = action.payload;
			if (data) {
				currState.customer = null;
				currState.items = [];
				currState.subTotal = 0;
				currState.subItemTotal = 0;
				currState.total = 0;
				currState.totalAddOns = 0;
				// currState.discount = null;
				currState.subDiscounts = 0;
				currState.subItemDiscounts = 0;
				currState.tax = [];
				currState.notes = '';
				currState.payment = '';
				currState.order = null;
				currState.historyCollection = [];
			}
		},
		setOrdersToCart(state, action) {
			const currState = state;
			const data = action.payload;
			if (data) {
				const currDisc = { type: data.discount_type, value: data.discount };
				const subDiscounts = currState.subDiscounts;

				const items = data.products.map((d) => {
					return {
						...d,
						product: d.id,
						id: d.product.id,
						name: d.product.name,
						price: +d.unit_price,
						uid: uuidv4(),
						discount: d.discount_type ? { value: d.discount, type: d.discount_type } : null,
					};
				});
				currState.order = {
					id: data.id,
					bill: data.number,
					paidAt: data.paid_at,
					type: data.type,
				};

				const { valInlineDiscount, T, subT, subDisc, valModifiers, subTWithNoDiscInItm } = getEverything(items, currDisc, subDiscounts);

				currState.items = items;
				currState.subItemTotal = subTWithNoDiscInItm;
				currState.subItemDiscounts = valInlineDiscount;
				currState.subDiscounts = subDisc;
				currState.subTotal = subT;
				currState.total = T - (valInlineDiscount + subDisc);
				currState.totalAddOns = valModifiers;

				currState.discount = { uid: uuidv4(), type: data.discount_type, value: data.discount };
				currState.notes = data.note;
				currState.payment = data.payment_type?.id;
				currState.servingTime = data.serving_time;
				currState.customer = data.customer;
			}
		},
		setCustomer(state, action) {
			const currState = state;
			const data = action.payload;
			currState.customer = data;
		},
		setHistoryCollection(state, action) {
			const currState = state;
			const data = action.payload;
			const currHistory = currState.historyCollection || [];

			if (data.type === 'add') {
				const newCollection = data.data;
				currHistory.push(newCollection);
				currState.historyCollection = currHistory;
				currState.categoryFilter = null;
			}
			if (data.type === 'remove') {
				const newCollection = currHistory.slice(0, -1);
				currState.historyCollection = newCollection;
			}
			if (data.type === 'clear') {
				currState.historyCollection = [];
			}
		},
		reset(state) {
			const currState = state;
			currState.historyCollection = [];
		},
		setCategoryFilter(state, action) {
			const currState = state;
			const data = action.payload;
			const currCategoryFilter = currState.categoryFilter;
			const currHistoryCollection = currState.historyCollection;

			if (data > 0) {
				currState.categoryFilter = data === currCategoryFilter ? null : data;
			} else {
				currState.categoryFilter = currHistoryCollection.length ? data : currCategoryFilter === 'x' ? null : data;
				currState.historyCollection = [];
			}
		},
		setOpenOrderConfirmation(state, action) {
			const currState = state;
			const data = action.payload;
			currState.openOrderConfirmation = data;
		},
		setOpenPayment(state, action) {
			const currState = state;
			const data = action.payload;
			currState.openPayment = data;
		},
		setOrderDate(state, action) {
			const currState = state;
			const data = action.payload;
			currState.orderDate = data;
		},
		setSelectedItem(state, action) {
			const currState = state;
			const data = action.payload;
			currState.selectedItem = data;
		},
		setSelectedItemKey(state, action) {
			const currState = state;
			const data = action.payload;
			currState.selectedItem = data;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getCustomerByOrder.fulfilled, (state, action) => {
			const currState = state;
			currState.customer = action.payload || null;
		});
		builder.addCase(getDiscountCustomer.fulfilled, (state, action) => {
			const currState = state;
			currState.discount = action.payload?.discount || null;
		});
	},
});

export const cartActions = cartSlice.actions;
export default cartSlice;
