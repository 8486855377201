export default function getRemainingTime(expTime, config) {
	const countdownDateTime = new Date(expTime).getTime();
	const currentTime = new Date().getTime();
	const remainingDayTime = countdownDateTime - currentTime;
	const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
	const totalHours = Math.floor((remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	const totalMinutes = Math.floor((remainingDayTime % (1000 * 60 * 60)) / (1000 * 60));
	const totalSeconds = Math.floor((remainingDayTime % (1000 * 60)) / 1000);

	const runningCountdownTime = {
		cD: Math.abs(totalDays),
		cH: Math.abs(totalHours),
		cM: Math.abs(totalMinutes),
		cS: Math.abs(totalSeconds),
	};

    if (config?.setCountdownTime) {
        config.setCountdownTime(runningCountdownTime);
    }

    return remainingDayTime;
}
