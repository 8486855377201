import { Box, Modal } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';

import BaseInputValidation from '@/components/ui/Input/BaseInputValidation';
import ModalStyle from '@/constants/ModalStyle';
import { twMerge } from 'tailwind-merge';

const schema = yup.object().shape({
	notes: yup.string(),
});

export default function Notes(props) {
	const { open, onClose, data } = props;
	const from = props.from || 'cart';
	const isOrder = from === 'order';

	const dispatch = useDispatch();

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			notes: data.notes || '',
		},
	});

	const onSubmit = (d) => {
		if (props.onSubmit) {
			props.onSubmit(d.notes);
		} else {
			dispatch({ type: 'cart/setNotes', payload: { date: data.date, notes: d.notes } });
			onClose();
		}
	};

	const generateItemDescription = (data) => {
		const d = data.obj;
		let html = null;
		if (data.type === 'item') {
			html = (
				<div className='flex gap-2 mt-2'>
					<div className=''>
						<span className='w-8'>{d.quantity}x</span>
					</div>
					<div>
						<span>{d.product.name}</span>
						<div className='pl-4 flex flex-col gap-0'>
							{d.add_ons.length > 0 && d.add_ons.map((a) => <span key={a.id}>{a.add_on.name}</span>)}
							{d.modifiers.length > 0 && d.modifiers.map((a) => <span key={a.id}>{a.modifier.name}</span>)}
						</div>
					</div>
				</div>
			);
		} else if (data.type === 'bill') {
			html = <div className='mt-2'>Bill No: {d.number}</div>;
		} else if (data.type === 'date') {
			html = <div className='mt-2'>Date: {d.date}</div>;
		}
		return html;
	};

	const inputConfig = {};
	if (isOrder) {
		inputConfig.multiline = true;
		inputConfig.rows = 3;
	}
	return (
		<Modal open={open} onClose={onClose} aria-labelledby='modal-notes' aria-describedby='modal-notes' className='w-full'>
			<Box sx={{ ...ModalStyle, top: '25%' }} className='flex flex-col gap-4 w-[90%] md:w-[360px]'>
				<span className='font-bold text-center text-md'>{isOrder ? 'Cancel note' : 'Note'}</span>
				<form onSubmit={handleSubmit(onSubmit)} className={twMerge('flex flex-col', isOrder && props.dataCancel ? '' : 'space-y-4')}>
					<BaseInputValidation control={control} name='notes' label='' errors={errors} {...inputConfig} />
					{isOrder && props.dataCancel && generateItemDescription(props.dataCancel)}
					<div className={twMerge('flex gap-2 ml-auto mr-0', isOrder && props.dataCancel ? 'mt-4' : '')}>
						{isOrder && (
							<button className='bg-white text-black border-black border btn-actions min-w-max' onClick={onClose}>
								Cancel
							</button>
						)}
						<button className='bg-black btn-actions min-w-max' type='submit'>
							Save
						</button>
					</div>
				</form>
			</Box>
		</Modal>
	);
}
