import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import customers from '@/api/customers';

export const getCustomer = createAsyncThunk('auth/setCustomer', async (params) => {
	const { getCustomers } = customers();
	const response = await getCustomers(params.params, params.id);
	return response.data;
});

const authSlice = createSlice({
	name: 'auth',
	initialState: {
		isAuthenticated: false,
		isSuperUser: false,
		isGroups: [],
		key: Cookies.get('wbAKey'),
		user: Cookies.get('wbAUser') ? JSON.parse(Cookies.get('wbAUser')) : false,
		selectedOutlet: Cookies.get('wbA-o') ? JSON.parse(Cookies.get('wbA-o')) : false,
		selectedCustomer: null,

		isSSO: false,
	},
	reducers: {
		setLogginSSO(state, action) {
			const currState = state;
			const currSSO = false;

			const data = action.payload;
			const { token, user } = data;
			const userWithoutSidebar = { ...user, sidebar_items: null };
			const lang = localStorage.getItem('wbAlang');
			const langId = localStorage.getItem('wbAlangId');

			let expiryTime = 0.5;
			let selectedToken = token;
			if (currSSO) {
				expiryTime = token.expires_in / 86400;
				selectedToken = token.access_token;
			}

			if ((!lang || !langId) && user.client.languages.length > 0) {
				localStorage.setItem('wbALang', user.client.languages[0].abbreviation);
				localStorage.setItem('wbALangId', user.client.languages[0].id);
			} else {
				localStorage.setItem('wbALang', 'en');
				localStorage.setItem('wbALangId', 1);
			}
			localStorage.setItem('wbAKey', selectedToken);
			localStorage.setItem('wbAUser', JSON.stringify(userWithoutSidebar));

			if (!user?.is_superuser) {
				Cookies.set('wbAaClient', user.client.id, { expires: expiryTime });
				if (user.sites && user.sites.length > 0) {
					Cookies.set('wbASite', user.sites[0].id, { expires: expiryTime });
					localStorage.setItem('wbASiteId', user.sites[0].id);
				}
			}

			Cookies.set('wbAKey', selectedToken, { expires: expiryTime });
			Cookies.set('wbAUser', JSON.stringify(userWithoutSidebar), { expires: expiryTime });
			Cookies.set('wbAClient', JSON.stringify(user.client), { expires: expiryTime });

			if (user.client && selectedToken && user) {
				currState.client = user.client;
				currState.key = selectedToken;
				currState.user = user;
				currState.isAuthenticated = true;
				currState.isSuperUser = user.is_superuser;
				if (!user.is_superuser && user.groups.length) {
					currState.isGroups = user.groups?.map((gr) => gr.id);
				}
			}

			currState.selectedCustomer = data.user.customer;
			currState.selectedOutlet = data.user.customer.outlet;
			Cookies.set('jt-o', JSON.stringify(data.user.customer.outlet));
		},
		setAuthenticated(state) {
			const currState = state;
			const objState = current(currState);
			if (objState.key && objState.user) {
				currState.isAuthenticated = true;
				currState.isSuperUser = objState.user.is_superuser;

				if (!objState.user.is_superuser && objState.user.groups.length) {
					currState.isGroups = objState.user.groups?.map((gr) => gr.id);
				}
			}
		},
		setLogout(state) {
			const currState = state;
			currState.key = false;
			currState.user = false;
			currState.isAuthenticated = false;
			Cookies.remove('wbAKey', { path: '/' });
			Cookies.remove('wbAUser');
			localStorage.removeItem('wbAKey');
			localStorage.removeItem('wbAUser');
			localStorage.removeItem('isSSO');
			localStorage.removeItem('groupClient');
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getCustomer.fulfilled, (state, action) => {
			const currState = state;
			const currCustomer = currState.selectedCustomer;
			const currUser = currState.user;

			const customer = action.payload || currCustomer || null;
			const newUser = {...currUser, customer }

			currState.selectedCustomer = customer;
			currState.user = newUser;
		});
	},
});

export const authActions = authSlice.actions;
export default authSlice;
