import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const initialPagination = {
	page: 0,
	pageSize: 10,
};

const defaultState = {
	paginationModel: initialPagination,
	payment: [],
	date: dayjs(),
	search: '',
};
const filterProductSlice = createSlice({
	name: 'filterProduct',
	initialState: {
		products: defaultState,
	},
	reducers: {
		setFilter(state, action) {
			const currState = state;
			const params = action.payload;
			const { type, key, data } = params;
			if (type === 'products') {
				const currProducts = state.products;
				currState.products = { ...currProducts, [key]: data };
            }
		},
		resetFilter(state, action) {
			const currState = state;
			const data = action.payload;
			if (data) {
				currState.products = defaultState;
			}
		},
	},
});

export const contentActions = filterProductSlice.actions;
export default filterProductSlice;
