import { useEffect, useState } from 'react';
import { InputAdornment, IconButton, CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import BaseInputValidation from '@/components/ui/Input/BaseInputValidation';
import authSSO from '@/api/authSSO';
import store from '@/store';
import { getSubdomain } from '@/helper/getSubDomain';

import logo from '@/assets/jt-logo.jpg';

const schema = yup.object().shape({
	student_id: yup.string().required('Student ID is a required field'),
	password: yup.string().required('Password is a required field').min(8, 'Password min 8 characters'),
});

const { signin, signInSSO } = authSSO();

export default function Auth() {
	const innerHeight = useSelector((state) => state.content.innerHeight);
	const isSSO = false;
	const [values, setValues] = useState({ showPassword: false });
	const [loading, setLoading] = useState(false);

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			student_id: '',
			password: '',
		},
	});

	const handleClickShowPassword = () => {
		setValues({
			...values,
			showPassword: !values.showPassword,
		});
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const onSubmit = (dataAuth) => {
		setLoading(true);
		const actions = isSSO ? signInSSO : signin
		actions({
			username: dataAuth.student_id.toLowerCase(),
			password: dataAuth.password,
			// grant_type: 'password',
			// client_id: 'W08vGPLmig1Jyzktk8dsnIjsJNPfcbkr1CbRcZVc',
			// client_secret:
			// 	'WaBVvKswzHJ8vzlaIvPqVvAJrpNlbYWEaZj8IwxPkLLJCTPGtQuivQap17679bgZeP8J3zzK2XlDZN86D3xCP5PtRL5aJcqc1Z3WVmTY4DgcOoTmFW3iLjQhqIVUJrVj',

		})
			.then((res) => {
				const { data } = res;
				if (data && res.status === 200) {
					store.dispatch({ type: 'auth/setLogginSSO', payload: data });
					setTimeout(() => {
						window.location.replace('/');
					}, 300)
				}
				setLoading(false);
			})
			.catch((err) => {
				toast.error(err.response?.data?.detail || 'Error');
				setLoading(false);
			});
	};

	useEffect(() => {
		localStorage.setItem('isSSO', JSON.stringify(isSSO ? false : false));
	}, [isSSO])

	const school = getSubdomain(window.origin)
	return (
		<section style={{ minHeight: innerHeight }} className='app-container'>
			<form onSubmit={handleSubmit(onSubmit)} className='flex flex-col justify-between flex-1 w-full h-full gap-12 px-8 py-16'>
				<div className='flex flex-col gap-4 mx-auto'>
					<img src={logo} alt='' className='w-full h-36' />
					{school && <span className='font-bold text-center uppercase'>{school}</span>}
				</div>
				<div className='flex flex-col w-full gap-4'>
					<BaseInputValidation control={control} name='student_id' label='Student ID' errors={errors} />
					<BaseInputValidation
						control={control}
						name='password'
						label='Password'
						errors={errors}
						config={{ type: values.showPassword ? 'text' : 'password' }}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton
										aria-label='toggle password visibility'
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge='end'
									>
										{values.showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</div>
				<button className='relative flex items-center justify-center h-12 text-white bg-black rounded-md btn-default' type='submit'>
					Sign in {loading && <CircularProgress className='ml-2' size={18} sx={{ color: 'var(--white)' }} />}
				</button>
			</form>
		</section>
	);
}
