import Navigation from '@/components/Navigation/Navigation';
import ButtonBackHeader from '@/components/ui/Button/ButtonBackHeader';
import { Key } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import BaseInputPassword from '@/components/ui/Input/BaseInputPassword';
import CircleLoader from '@/components/ui/Loader/CircleLoader';
import usePatch from '@/hooks/request/usePatch';
import { route } from '@/constants/routeName';

const schema = yup.object().shape({
	old_password: yup.string().required('Current password is a required field').min(8, 'Password min 8 characters'),
	new_password: yup.string().required('New password is a required field').min(8, 'Password min 8 characters'),
	confirm_password: yup.string().required('Confirm password is a required field').min(8, 'Password min 8 characters'),
});

export default function UpdatePassword() {
    const navigate = useNavigate()
    const user = useSelector((state) => state.auth.user);

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			email: '',
			password: '',
		},
    });

    const {
		mutate: updatePassword,
		isSuccess,
		isPending,
	} = usePatch({
		url: `users/${user.id}/update_password`,
		method: 'put',
		callback: () => {
			toast.success('Successfully changed password');
			navigate(`/${route.account}`);
		},
		onError: () => {
			toast.error('There is an Error when updating password');
		},
		showError: true,
    });

    const onSubmit = (formData) => {
        if (!user) return;
        updatePassword(formData)
    };
	return (
		<div style={{ minHeight: innerHeight }} className='app-container'>
			<div className='flex flex-col w-full h-full gap-12 p-4'>
				<ButtonBackHeader />
				<div className='relative flex items-center justify-center'>
					<Key />
					<span className='ml-4 font-bold'>Update Password</span>
				</div>
				<form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-4'>
					<BaseInputPassword name='old_password' label='Current' control={control} errors={errors} />
					<BaseInputPassword name='new_password' label='New Password' control={control} errors={errors} />
					<BaseInputPassword name='confirm_password' label='Confirm Password' control={control} errors={errors} />
					<button className='btn-actions mt-8' type='submit' disabled={!user}>
						Confirm {(isSuccess || isPending) && <CircleLoader />}
					</button>
				</form>
			</div>
			<Navigation />
		</div>
	);
}
