import { useNavigate, useRouteError } from 'react-router-dom';

const mode = import.meta.env.VITE_MODE;

export const ErrorElement = () => {
	const error = useRouteError();
	const navigate = useNavigate();
	return (
		<div className='w-full h-[100svh] flex flex-col m-auto justify-center items-center gap-2'>
			<div className='w-[90%] sm:w-auto border p-4 rounded-sm flex flex-col gap-4 justify-center items-center flex-wrap'>
				<p className='font-bold'>Something went wrong:</p>
				<hr className='w-full' />
				<p className='text-red-500 font-bold'>{error.message}</p>
				{mode === 'development' ||
					(['localhost', '127.0.0.1'].includes(window.location.hostname) && <pre className='text-red-500 font-bold'>{error.stack}</pre>)}
				<hr className='w-full' />

				<div className='flex flex-wrap gap-2'>
					<button
						className='relative flex items-center justify-center h-10 text-white bg-black rounded-md btn-default px-4'
						onClick={() => navigate(0)}
					>
						Try again
					</button>
					<a href='/' className='relative flex items-center justify-center h-10 text-white bg-black rounded-md btn-default px-4'>
						Back to Home
					</a>
				</div>
			</div>
		</div>
	);
};
