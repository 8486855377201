import ButtonBackHeader from '@/components/ui/Button/ButtonBackHeader';
import nf from '@/constants/nf';
import { Checkbox, FormControl, FormLabel, RadioGroup } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns';

import QuantityControl from './QuantityControl';
import ServingItem from './ServingItem';
import { getCutOffTime } from '@/helper/getCutOffTime';
import getRemainingTime from '@/helper/getRemainingTime';
import { getDiscountItem } from '@/store/slices/cartSlice';
import { getServingTimes } from '@/store/slices/contentSlice';
import WarningServingTime from '@/components/Modal/WarningServingTime';

const AddOnModifier = ({ data, selected, onCheck }) => {
	return (
		<div className='carts add-ons'>
			<span className={twMerge('')}>{data.name}</span>
			<span className={twMerge('')}>{data.price ? `${Math.sign(data.price) !== -1 ? '+' : '' }${nf(data.price)}` : 'Free'}</span>
			<Checkbox className='ck-table' checked={!!selected} onChange={() => onCheck(data)} inputProps={{ 'aria-label': 'controlled' }} />
		</div>
	);
};

export default function Item() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const item = useSelector((state) => state.cart.selectedItem);
	const servingTimes = useSelector((state) => state.content.servingTimes);
	const orderDate = useSelector((state) => state.cart.orderDate);
	const utcOffset = useSelector((state) => state.content.utcOffset);
	const outlet = useSelector((state) => state.content.outlet);
	const selectedCustomer = useSelector((state) => state.auth.selectedCustomer);
	const discountCustomer = useSelector((state) => state.cart.discount);
	const selectedOutlet = useSelector((state) => state.auth.selectedOutlet);

	const [addOns, setAddOns] = useState([]);
	const [modifiers, setModifiers] = useState([]);
	const [servingTime, setServingTime] = useState('');
	const [amount, setAmount] = useState(1);
	const [showWarning, setShowWarning] = useState(false);

	const add_ons = item?.add_ons;
	const modifier = item?.modifiers;

	const isA = add_ons?.length > 0;
	const isM = modifier?.length > 0;

	// const onCheck = (key, id, checked) => {
	// 	dispatch({ type: 'cart/setSelectedItemKey', payload: { key, id, checked } });
	// };
	const onAddOns = (itm) => {
		if (addOns.find((a) => a.id === itm.id)) {
			setAddOns((prevState) => prevState.filter((a) => a.id !== itm.id));
		} else {
			setAddOns((prevState) => prevState.concat([itm]));
		}
	};
	const onAddModifiers = (itm) => {
		if (modifiers.find((a) => a.id === itm.id)) {
			setModifiers((prevState) => prevState.filter((a) => a.id !== itm.id));
		} else {
			setModifiers((prevState) => prevState.concat([itm]));
		}
	};
	const handleChangeServing = (event) => {
		setServingTime(event.target.value);
	};
	const changeAmount = (key) => {
		setAmount((prevState) => {
			if (key === 'plus') {
				return prevState + 1;
			}
			if (prevState === 1) return 1;
			return prevState - 1;
		});
	};
	const getAddOnsModifier = () => {
		const newAddOns = addOns.map((a) => {
			return { id: uuidv4(), add_on: { ...a } };
		});
		const newModifiers = modifiers.map((a) => {
			return { id: uuidv4(), modifier: { ...a } };
		});
		return { addOns: newAddOns, modifiers: newModifiers };
	};
	const getSTime = (s) => {
		const { oCutOff, sT } = getCutOffTime(s, orderDate, utcOffset);
		const remainingDayTime = getRemainingTime(oCutOff);
		let isDisable = disableOrder || remainingDayTime < 0 ? true : false;

		return { isDisable, oCutOff, sT };
	};

	const disableOrder =
		'use_minimum_balance_limit' in outlet &&
		outlet?.use_minimum_balance_limit &&
		'minimum_balance_limit' in outlet &&
		+outlet?.minimum_balance_limit > +selectedCustomer.balance;

	const onAddToCart = () => {
		const { addOns, modifiers } = getAddOnsModifier();
		const sTime = servingTime ? servingTimes.find((s) => +s.id === +servingTime) : null;
		if (!sTime) return;
		const { isDisable } = getSTime(sTime);

		if (isDisable) {
			setShowWarning(true);
			return;
		}
		dispatch({
			type: 'cart/setItems',
			payload: {
				...item,
				quantity: amount,
				is_void: false,
				modifiers: modifiers,
				add_ons: addOns,
				serving_time: sTime,
				date: orderDate,
				uid: uuidv4(),
			},
		});
		dispatch(getServingTimes({ outlet: selectedOutlet?.id, max_size: true }));
		navigate(-1);
	};

	let totalPrice = (+item?.selling_price || 0) * amount;
	let totalAddOns = 0;
	let discValue = 0;
	if (addOns.length) {
		totalAddOns = addOns.reduce((a, b) => +a + +b.price * amount, 0);
		totalPrice += totalAddOns;
	}

	if (discountCustomer) {
		discValue = getDiscountItem(+item?.selling_price || 0, amount, discountCustomer) || 0;
		discValue += getDiscountItem(totalAddOns, 1, discountCustomer) || 0;
		totalPrice -= discValue;
	}

	useEffect(() => {
		if (!item) {
			navigate(-1);
		}
	}, [item, navigate]);

	let discVal = 0;
	if (discountCustomer) {
		const { type, value } = discountCustomer;
		discVal = `${value}${type === 'Percentage' ? '%' : ''}`;
	}
	const uBalance = +selectedCustomer?.balance || 0;
	const showTextWarning = uBalance < 0;
	return (
		<div className='app-container'>
			<div className='relative flex flex-col flex-1 gap-6 p-4'>
				<ButtonBackHeader />
				<div className='relative flex flex-col items-center justify-center'>
					<span className='font-bold'>{item?.name}</span>
					<div>
						<span className='font-bold'>{nf(+item?.selling_price)}</span>
					</div>
				</div>
				{(isA || isM) && (
					<div className='flex flex-col'>
						{isA &&
							add_ons.map((a) => {
								const selected = addOns.find((m) => m.id === a.id);
								return <AddOnModifier key={`add-ons-${a?.id}-${item?.uid}`} data={a} onCheck={onAddOns} selected={selected} />;
							})}
						{isM &&
							modifier.map((m) => {
								const selected = modifiers.find((a) => a.id === m.id);
								return <AddOnModifier key={`modifier-${m?.id}-${item?.uid}`} data={m} onCheck={onAddModifiers} selected={selected} />;
							})}
					</div>
				)}
				<FormControl>
					<FormLabel id='demo-controlled-radio-buttons-group'>Serving Times</FormLabel>
					<RadioGroup
						aria-labelledby='demo-controlled-radio-buttons-group'
						name='controlled-radio-buttons-group'
						value={servingTime}
						onChange={handleChangeServing}
					>
						{servingTimes?.map((s) => {
							const { isDisable, oCutOff, sT } = getSTime(s);
							const sTime = sT;
							return (
								<ServingItem
									key={s.id}
									item={s}
									oCutOff={oCutOff}
									isDisable={isDisable}
									servingTime={sTime ? format(sTime, 'HH:mm OOOO') : ''}
								/>
							);
						})}
					</RadioGroup>
				</FormControl>
				<div className='flex justify-between'>
					<span>Purchase Amount</span>
					<QuantityControl amount={amount} changeAmount={changeAmount} disabled={amount === 0} />
				</div>
				<div className='flex justify-between'>
					<span>Order Date</span>
					<span>{dayjs(orderDate).format('dddd, DD MMMM YYYY')}</span>
				</div>
				{discountCustomer && (
					<div className='flex justify-between'>
						<span>Discount {`(${discountCustomer.name})`}</span>
						<span>{discVal}</span>
					</div>
				)}
				{showTextWarning && (
					<span>
						Sorry, your deposit is insufficient. After your recent order your remaining balance is{' '}
						<span className='text-red-500'>{nf(+selectedCustomer.balance || 0)}</span>. Please contact our admin for your top up. Kind
						regards Jungle Toppings
					</span>
				)}
				<button className='flex flex-col btn-actions' onClick={onAddToCart} disabled={amount === 0 || !servingTime}>
					<span className='ml-1'>Add To Cart {nf(totalPrice)}</span>
					{discValue > 0 && <span className='text-sm font-bold'>disc: -{nf(discValue)}</span>}
				</button>
			</div>
			<WarningServingTime open={showWarning} onClose={() => setShowWarning(false)} />
		</div>
	);
}
