import { Navigate } from 'react-router-dom';

import PublicLayout from '@/components/Layout/PublicLayout';
import Auth from '@/pages/Auth';
import AuthProvider from '@/components/AuthProvider';
// import Notification from '@/pages/Notification';

const notAuthenticatedRoutes = [
	{
		path: '/',
		element: (
			<AuthProvider>
				<PublicLayout />
			</AuthProvider>
		),
		children: [
			// {
			// 	path: '/notification',
			// 	element: <Notification />,
			// },
			{
				path: '/login',
				element: <Auth />,
				// element: <Navigate to='/notification' />,
			},
			{
				path: '*',
				element: <Navigate to='/login' />,
			},
			{
				path: '',
				element: <Navigate to='/login' />,
			},
		],
	},
];
export default notAuthenticatedRoutes;
