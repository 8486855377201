import { FormControlLabel, Radio } from '@mui/material';
import { useState } from 'react';
import CountdownServing from './CountdownServing';

export default function ServingItem({ item, oCutOff, isDisable, servingTime }) {
	const [disable, setDisable] = useState(isDisable);

	return (
		<div className='flex flex-wrap justify-between'>
			<FormControlLabel
				key={item.id}
				value={item.id}
				control={<Radio disabled={disable} />}
				label={
					<div className='flex items-center gap-2'>
						<span>{item.name}</span>
						<span className='text-sm'>{servingTime}</span>
					</div>
				}
			/>
			<CountdownServing expTime={oCutOff} whenExpired={setDisable} isDisable={isDisable} />
		</div>
	);
}
