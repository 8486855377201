/* eslint-disable no-mixed-spaces-and-tabs */
import { useDispatch, useSelector } from 'react-redux';
import { Check, Close, Edit, EventBusy, ExpandMore, Summarize } from '@mui/icons-material';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Accordion, AccordionDetails, AccordionSummary, Pagination } from '@mui/material';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import Navigation from '@/components/Navigation/Navigation';
import UserHeader from '@/components/User/UserHeader';
import Loader from '@/components/ui/Loader/Loader';
import LabelCartItem, { LabelCartItemAdd, LabelCartItemsChild, LabelNotes } from '@/components/ui/Label/LabelCartItem';
import Notes from '@/components/Modal/Notes';

import { generateDiscountValue, getEverything } from '@/store/slices/cartSlice';
import { getCustomer } from '@/store/slices/authSlice';
import usePatch from '@/hooks/request/usePatch';
import nf from '@/constants/nf';
import { getCutOffTime } from '@/helper/getCutOffTime';
import getRemainingTime from '@/helper/getRemainingTime';
import orders from '@/api/orders';

const { getOrders } = orders();
const pageSize = 20;

export default function Orders() {
	const dispatch = useDispatch();
	const innerHeight = useSelector((state) => state.content.innerHeight);
	const selectedCustomer = useSelector((state) => state.auth.selectedCustomer);
	const payType = useSelector((state) => state.content.payType);
	const selectedOutlet = useSelector((state) => state.auth.selectedOutlet);
	const objServingTimes = useSelector((state) => state.content.objServingTimes);
	const utcOffset = useSelector((state) => state.content.utcOffset);

	// const [date, setDate] = useState(new Date());
	const [page, setPage] = useState(1);
	const [edit, setEdit] = useState(false);
	const [selectedCancel, setSelectedCancel] = useState(null);
	const [openNotes, setOpenNotes] = useState(false);
	const [cancellationItems, setCancellationItems] = useState([]);

	const objParam = useMemo(() => {
		const obj = { outlet: selectedOutlet.id, page_size: pageSize };
		obj.type__in = ['On the spot', 'Customer App'];
		if (page > 1) {
			obj.page = page;
		}
		return obj;
	}, [selectedOutlet, page]);

	const {
		data: d,
		isFetching,
		isFetched,
		refetch,
	} = useQuery({
		queryKey: ['my-orders', selectedCustomer, objParam],
		enabled: !!selectedCustomer,
		queryFn: () => getOrders({ customer: selectedCustomer?.id, ...objParam, ordering: '-id' }),
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});

	const orderList =
		useMemo(() => {
			let obj = d?.data?.results
				? d.data.results.reduce((group, product) => {
						const { date } = product;
						const dateKey = dayjs(date).format('YYYY-MM-DD');
						group[dateKey] = group[dateKey] ?? [];
						group[dateKey].push(product);
						return group;
				  }, {})
				: [];
			if (typeof obj === 'object' && Object.keys(obj).length) {
				obj = Object.keys(obj)
					.map((o) => {
						return { date: o, orders: obj[o] };
					})
					.sort((a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? -1 : 1));
			}
			return obj;
		}, [d]) || [];
	const orderCount = useMemo(() => {
		return d?.data?.count ? Math.floor(d?.data?.count / pageSize) : 0;
	}, [d]);

	const handleChangePagination = (event, value) => {
		setPage(value);
	};

	const getSTime = (s, orderDate) => {
		const { oCutOff, sT } = getCutOffTime(s, orderDate, utcOffset);
		const remainingDayTime = getRemainingTime(oCutOff);
		let isDisable = remainingDayTime < 0 ? true : false;

		return { isDisable, oCutOff, sT };
	};

	const onCancelItem = (e, type, obj) => {
		e.stopPropagation();

		// const isExist = cancellationItems.length ? cancellationItems.find((c) => c.type === type && c.obj.id === obj.id) : false;
		if (obj.cancelled) {
			setCancellationItems(cancellationItems.filter((c) => c.type !== type && c.obj.id === obj.id));
		} else {
			setSelectedCancel({ type, obj });
			setOpenNotes(true);
		}
	};

	const onSubmitCancel = (data) => {
		if (selectedCancel) {
			setCancellationItems((prevState) => {
				let currState = prevState;
				let obj = null;
				const isExist = cancellationItems.findIndex((c) => c.type === selectedCancel.type && c.obj.id === selectedCancel.obj.id);
				if (isExist === -1) {
					obj = { ...selectedCancel, note: data };
				} else {
					currState[isExist].note = data;
				}
				if (obj) {
					return [...currState, { ...obj }];
				}
				return [...currState];
			});
			setOpenNotes(false);
			setSelectedCancel(null);
		}
	};

	const {
		mutate: voidOrders,
		isSuccess: isSuccessVO,
		isPending: isPendingVO,
	} = usePatch({
		url: `point-of-sale/orders/void_bulk`,
		method: 'post',
		callback: () => {
			if (selectedCustomer) {
				dispatch(getCustomer({ params: {}, id: selectedCustomer?.id }));
			}
			refetch();
			toast.success(`Order voided`);
			setEdit(false);
			setCancellationItems([]);
		},
		onError: (res) => {
			toast.error(`${res.status} - ${res.statusText}`);
		},
		showError: true,
	});

	const onSave = () => {
		if (!cancellationItems.length) return;
		const listCancel = [];
		cancellationItems.forEach((i) => {
			if (i.type === 'item') {
				const isOrderCancelled = cancellationItems.find(
					(itm) => (itm.type === 'bill' && itm.obj.id === i.obj.order) || (itm.type === 'date' && itm.obj.date === i.obj.date)
				);
				const { isDisable } = i.obj.serving_time ? getSTime(objServingTimes[i.obj.serving_time?.id], i.obj.date) : true;

				if (!isOrderCancelled && !isDisable) {
					listCancel.push({
						order: i.obj.order,
						payment_type: payType.deposit,
						// shift: selectedShift.id,
						order_products: [{ id: i.obj.id, note: i.note }],
					});
				}
			}
			if (i.type === 'bill') {
				const isOrderCancelled = cancellationItems.find(
					(itm) => itm.type === 'date' && itm.obj.date === dayjs(i.created_at).format('YYYY-MM-DD')
				);
				if (!isOrderCancelled) {
					listCancel.push({
						order: i.obj.id,
						payment_type: payType.deposit,
						// shift: selectedShift.id,
						order_products: i.obj?.products
							.filter((p) => {
								const { isDisable } = i.obj.serving_time ? getSTime(objServingTimes[i.obj.serving_time?.id], i.obj.created_at) : true;
								return !p.void_order && !isDisable;
							})
							.map((p) => {
								return { id: p.id, note: i.note };
							}),
					});
				}
			}
			if (i.type === 'date') {
				i.obj.orders
					?.filter((o) => o.status != 'void')
					.forEach((o) => {
						listCancel.push({
							order: o.id,
							payment_type: payType.deposit,
							// shift: selectedShift.id,
							order_products: o?.products
								.filter((p) => {
									const { isDisable } = i.obj.serving_time ? getSTime(objServingTimes[i.obj.serving_time?.id], i.obj.created_at) : true;
									return !p.void_order && !isDisable;
								})
								.map((p) => {
									return { id: p.id, note: i.note };
								}),
						});
					});
			}
		});
		const listData = listCancel.filter((l) => l.order_products.length);

		if (listData.length) {
			voidOrders(listData);
		}
	};
	return (
		<div style={{ minHeight: innerHeight }} className='text-sm app-container'>
			<div className='flex flex-col w-full h-full gap-6 p-4'>
				<UserHeader />
				<div className='flex justify-between'>
					<div className='flex gap-4'>
						<Summarize />
						<span className='font-bold'>My Order History</span>
						{!edit && <Edit onClick={() => setEdit(true)} className='cursor-pointer' style={{ color: 'var(--bzGreen)' }} />}
					</div>
					{edit && (
						<div className='flex items-center gap-1 flex-nowrap'>
							<span>Save?</span>
							<Check className='cursor-pointer' onClick={onSave} style={{ color: 'var(--bzGreen)' }} />
							<Close
								className='cursor-pointer'
								onClick={() => {
									setCancellationItems([]);
									setEdit(false);
								}}
								style={{ color: 'var(--error)' }}
							/>
						</div>
					)}
				</div>
				<div className='relative flex flex-col pb-24 min-h-24'>
					{isFetching || (isPendingVO && !isSuccessVO && <Loader />)}

					{isFetched &&
						orderList?.length > 0 &&
						orderList.map((k) => {
							let subTotals = 0;
							let quantityTotals = 0;

							const enableDateOrderCancel = [];
							const cItems = k.orders
								?.filter((o) => !['Deposit', 'Deposit void'].includes(o.type) && !o.origin_number)
								.map((o) => {
									const isOrderVoid = o.origin_number;
									const oMultiplication = isOrderVoid ? -1 : 1;
									const isFullVoid = o.status === 'Void' && o.products.every((p) => !!p.void_order);
									// const { subT, totalModifiers } = getSubTotal(o.products);
									// const valModifiers = totalModifiers.length ? totalModifiers.reduce((a, b) => a + b, 0) : 0;

									let discount = o.discount_type ? { type: o.discount_type, value: +o.discount } : null;

									// const currProducts = o?.products?.filter((p) => !p.is_void) || [];
									const currProducts = o?.products || [];
									const items = currProducts?.map((p) => {
										const obj = { ...p, price: +p.unit_price };
										obj.discount = p.discount_type ? { type: p.discount_type, value: p.discount } : null;

										return obj;
									});

									const { valInlineDiscount, subT, valModifiers } = getEverything(items || [], discount, 0);
									// const discValue = getDiscountValue(discount, items, subTWithNoDiscInItm);

									const total = subT + valModifiers;
									const totalDiscount = valInlineDiscount;
									const cancelEnablers = [];
									const orderProducts = o.products?.map((item) => {
										const isST = item.serving_time && objServingTimes[item.serving_time?.id];
										const { isDisable } = isST ? getSTime(objServingTimes[item.serving_time?.id], k.date) : true;
										const enableCancel = edit && item.serving_time && !isDisable && !item.void_order;
										cancelEnablers.push(enableCancel);

										quantityTotals += item.quantity;
										let discItem = 0;
										if (item.discount) {
											discItem = generateDiscountValue(
												{ ...item, discount: { value: item.discount, type: item.discount_type } },
												item.is_void
											);
										}

										const showDiscItem = discItem.disc > 0;
										const discName = item.discount_name || o.discount_name || '';
										const isCancel = cancellationItems?.find((i) => i.type === 'item' && i.obj.id === item.id);
										return (
											<div key={item.id} className='flex flex-col gap-2'>
												<LabelCartItem
													item={{ ...item, isFullVoid, isCancel }}
													value1={`${item.quantity}x`}
													value2={item.product.name}
													value3={+item.unit_price * oMultiplication}
													config={{
														from: 'order',
														enableCancel: enableCancel,
														onCancel: (e) => onCancelItem(e, 'item', { ...item, date: o.date, cancelled: !!isCancel }),
													}}
												/>
												{/* {item.void_order && <span>void: {item.void_order.number}</span>} */}
												{item.add_ons?.map((a) => {
													return (
														<LabelCartItemsChild
															key={a.id}
															label={a.add_on?.name || a.name}
															value={a.price ? `${nf(+a.price * oMultiplication)}` : 'Free'}
														/>
													);
												})}
												{item.modifiers?.map((a) => {
													return <LabelCartItemsChild key={a.id} label={a.modifier?.name || a.name} value={'Free'} />;
												})}
												{showDiscItem && (
													<LabelCartItemAdd label={`disc (${nf(discItem.disc)}) ${discName ? `- ${discName}` : ''}`} value='' />
												)}
												{/* {!!item.discount_amount && +item.discount_amount > 0 && (
													<LabelCartItemsChild
														label={`${item.discount_type} ${nf(item.discount)}${item.discount_type === 'Percentage' ? '%' : ''}`}
														value={`-${nf(item.discount_amount)}`}
													/>
												)} */}
												{item.serving_time ? (
													<LabelCartItemAdd label='Serving Time' value={item.serving_time?.name} />
												) : (
													<LabelCartItemAdd label='On the spot' value='' />
												)}
												{isCancel && <LabelCartItemAdd label={`cancel: ${isCancel.note}`} value='' />}
											</div>
										);
									});
									const subTotal = total - totalDiscount;
									subTotals += !isOrderVoid ? subTotal : 0;
									const totalDisc = valInlineDiscount || 0;
									const enableBillCancel = edit && cancelEnablers.some((e) => !!e);
									enableDateOrderCancel.push(enableBillCancel);
									const isCancelByBill = cancellationItems?.find((i) => i.type === 'bill' && i.obj.number === o.number);
									const voidStyle = o.payment_type.id === payType.void || isFullVoid || isCancelByBill ? 'line-through' : '';

									return (
										<div key={o.number}>
											<div>
												<div className='flex flex-wrap gap-1'>
													<div className='flex flex-wrap items-center gap-1'>
														<span className={twMerge('font-bold', voidStyle)}>Bill No: {o.number}</span>
														{enableBillCancel && (
															<Close
																className='cursor-pointer'
																style={{ color: 'var(--error)' }}
																onClick={(e) => onCancelItem(e, 'bill', { ...o, cancelled: !!isCancelByBill })}
															/>
														)}
													</div>
													{isOrderVoid && <span className='ml-1'>(void)</span>}
													{/* {!isOrderVoid && o.status === 'Void' && <span className='ml-1'>(voided)</span>} */}
													{!isOrderVoid && isFullVoid && <span className='font-bold text-red-500'>Canceled</span>}
												</div>
												{isCancelByBill && <LabelCartItemAdd label={`cancel: ${isCancelByBill.note}`} value='' />}
												{/* {isOrderVoid && <span>origin: {isOrderVoid}</span>} */}
											</div>
											{orderProducts}
											{totalDisc > 0 && (
												<>
													<div className='carts items-child'>
														<span className='font-semibold text-right'>Total</span>
														<span className='text-right'>{nf(total)}</span>
													</div>
													<div className='carts items-child'>
														<span className='font-semibold text-right'>Total Discount</span>
														<span className='text-right'>-{nf(totalDiscount)}</span>
													</div>
												</>
											)}
											<div className='carts items-child'>
												<span className='font-semibold text-right'>Sub Total</span>
												<span className='text-right'>{nf(subTotal * oMultiplication)}</span>
											</div>
											<LabelCartItemAdd label='Payment' value={o.payment_type?.name} />
											{o.note && <LabelNotes notes={o.note}>Note</LabelNotes>}
										</div>
									);
								});
							const date = dayjs(k.date).format('dddd, DD MMMM YYYY');

							if (cItems.length === 0) return null;
							const enableDateCancel = edit && enableDateOrderCancel.some((e) => !!e);
							const isCancelByDate = cancellationItems?.find((i) => i.type === 'date' && i.obj.date === k.date);
							return (
								<Accordion key={k.date}>
									<AccordionSummary expandIcon={<ExpandMore />} aria-controls='panel1-content' id='panel1-header'>
										<div className='flex items-center justify-between w-full gap-2 font-bold'>
											<div>
												<div className='flex flex-wrap items-center gap-1'>
													<span>{date}</span>
													{enableDateCancel && (
														<EventBusy
															onClick={(e) => onCancelItem(e, 'date', { ...k, cancelled: !!isCancelByDate })}
															className='cursor-pointer'
															style={{ color: 'var(--error)' }}
														/>
													)}
												</div>
												{isCancelByDate && <span>cancel: {isCancelByDate.note}</span>}
											</div>
											<div className='flex gap-2'>
												<span>({nf(quantityTotals)})</span>
												<span>{nf(subTotals)}</span>
											</div>
										</div>
									</AccordionSummary>
									<AccordionDetails>
										<div className='flex flex-col gap-2'>
											<div className='flex flex-col gap-4'>{cItems}</div>
										</div>
									</AccordionDetails>
								</Accordion>
							);
						})}
					{isFetched && ((typeof orderList === 'object' && Object.keys(orderList).length === 0) || orderList?.length === 0) && (
						<span className='w-full text-center'>No order found</span>
					)}
					{isFetched && <Pagination className='mt-4 ml-auto' count={orderCount} page={page} onChange={handleChangePagination} />}
				</div>
			</div>
			<Navigation />
			{!!openNotes && (
				<Notes
					open={!!openNotes}
					onClose={() => {
						setOpenNotes(false);
						setSelectedCancel(null);
					}}
					data={openNotes}
					from='order'
					onSubmit={onSubmitCancel}
					dataCancel={selectedCancel}
				/>
			)}
		</div>
	);
}
