import { useSelector } from 'react-redux';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import authenticatedRoutes from './AuthenticatedRoutes';
import notAuthenticatedRoutes from './NotAuthenticatedRoutes';

export default function Router() {
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

	const isAuth = !!isAuthenticated;

	const routesForAuthenticated = authenticatedRoutes;
	const routesForNotAuthenticated = notAuthenticatedRoutes;

	const r = !isAuth ? routesForNotAuthenticated : routesForAuthenticated || []

	const router = createBrowserRouter([...r]);

	return <RouterProvider router={router} />;
}
