import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import Loader from '@/components/ui/Loader/Loader';
import UserHeader from '@/components/User/UserHeader';
import ItemList from './ItemList';
import Categories from './Categories';
import Navigation from '@/components/Navigation/Navigation';
import items from '@/api/items';
import FilterOrder from '@/components/Order/FilterOrder';
import pos from '@/api/pos';

const { getItems, getCollections, getInnerCollections, getHomeCollections } = items();
const { getProducts } = pos();


export default function Home() {
	// eslint-disable-next-line no-unused-vars
	const dispatch = useDispatch();
	const selectedOutlet = useSelector((state) => state.auth.selectedOutlet);
	const historyCollection = useSelector((state) => state.cart.historyCollection);
	const categoryFilter = useSelector((state) => state.cart.categoryFilter);
	const orderDate = useSelector((state) => state.cart.orderDate);

	const [search, setSearch] = useState('');

	const [searchParam] = useDebounce(search, 500);

	const currDate = useMemo(() => {
		return dayjs(orderDate).format('YYYY-MM-DD');
	}, [orderDate]);

	const objParam = useMemo(() => {
		const obj = { outlet: selectedOutlet?.id, date: currDate, max_size: true, ordering: 'index' };
		// if (searchParam || categoryFilter === 'x' || historyCollection.length) {
		// 	delete obj.is_initial;
		// }
		if (searchParam) {
			obj.search = searchParam;
		}
		return obj;
	}, [currDate, selectedOutlet, searchParam]);

	const collectionParams = useMemo(() => {
		const newObjParam = objParam;
		if (historyCollection?.length) {
			// newObjParam.collection__parent_collections = historyCollection[historyCollection.length - 1]?.id;
			newObjParam.parent_collections = historyCollection[historyCollection.length - 1]?.id;
			// newObjParam.ordering = 'collection__name';
		} else {
			// if (newObjParam.collection__parent_collections) delete newObjParam.collection__parent_collections;
			if (newObjParam.parent_collections) delete newObjParam.parent_collections;
		}
		if (categoryFilter && categoryFilter === 'x') {
			newObjParam.ordering = 'product__name';
		}
		return newObjParam;
	}, [objParam, historyCollection, categoryFilter]);

	const itemParams = useMemo(() => {
		const newObjParam = objParam;
		if (historyCollection?.length) {
			newObjParam.product__collections = historyCollection[historyCollection.length - 1]?.id;
			if (categoryFilter && categoryFilter !== 'x') {
				newObjParam.product__category = categoryFilter;
			}
			// newObjParam.ordering = 'product__name';
		} else {
			if (newObjParam.product__collections) delete newObjParam.product__collections;
			if (categoryFilter && categoryFilter !== 'x') {
				newObjParam.product__category = categoryFilter;
			}
		}
		if (categoryFilter && categoryFilter === 'x') {
			newObjParam.ordering = 'product__name';
		}
		return newObjParam;
	}, [objParam, historyCollection, categoryFilter]);

	const { isFetched: isFCD } = useQuery({
		queryKey: ['collection-ids', selectedOutlet],
		enabled: !!selectedOutlet,
		queryFn: () => getCollections({ outlet: selectedOutlet?.id, max_size: true }),
		refetchOnWindowFocus: false,
	});

	// const cIds = useMemo(() => cId?.data?.results?.map((c) => c.id), [cId]);
	const allProductsPram = useMemo(() => {
		const objParam = { collections__outletcollection__outlet: selectedOutlet?.id || [], date: currDate, max_size: true, ordering: 'name' };
		if (historyCollection?.length) {
			objParam.collections = historyCollection[historyCollection.length - 1]?.id;
			if (categoryFilter && categoryFilter !== 'x') {
				objParam.category = categoryFilter;
			}
		}
		if (searchParam) {
			objParam.search = searchParam;
		}
		return objParam;
	}, [selectedOutlet, currDate, historyCollection, categoryFilter, searchParam]);

	const fetchCollections = !!selectedOutlet && !!isFCD && categoryFilter !== 'x' && historyCollection?.length > 0 && !searchParam;
	const fetchHomeCollections = !!selectedOutlet && !!isFCD && categoryFilter !== 'x' && !historyCollection?.length && !searchParam;

	const {
		data: c,
		isFetching: isFC,
		isFetched: isFD,
	} = useQuery({
		queryKey: ['collections', collectionParams],
		enabled: fetchCollections,
		queryFn: () => getInnerCollections(collectionParams),
		refetchOnWindowFocus: false,
	});

	const {
		data: hc,
		isFetching: isFHC,
		isFetched: isFHD,
	} = useQuery({
		queryKey: ['home-collections', collectionParams],
		enabled: fetchHomeCollections,
		queryFn: () => getHomeCollections({ outlet: selectedOutlet?.id }),
		refetchOnWindowFocus: false,
	});

	const fetchHomeProducts = !!isFHD && !!selectedOutlet && !!isFCD && categoryFilter !== 'x' && !historyCollection?.length && !searchParam;

	const {
		data: d,
		isFetching: isFI,
		isFetched,
	} = useQuery({
		queryKey: ['items', itemParams],
		enabled: fetchHomeProducts,
		queryFn: () => getItems(itemParams),
		refetchOnWindowFocus: false,
	});

	const fetchProducts = (categoryFilter && categoryFilter === 'x') || (categoryFilter !== 'x' && historyCollection?.length > 0) || !!searchParam;
	const {
		data: dAll,
		isFetching: isFA,
		isFetchedAll,
	} = useQuery({
		queryKey: ['all-product-items', allProductsPram],
		enabled: fetchProducts,
		queryFn: () => getProducts(allProductsPram),
		refetchOnWindowFocus: false,
	});

	const isStillFetching = isFC || isFHC || isFI || isFA;
	const isDoneFetched = isFD || isFHD || isFetched || isFetchedAll;

	const itemsList =
		useMemo(() => {
			const col =
				c?.data?.results.map((cc) => {
					return { ...cc, collection: { ...cc }, name: cc.name };
				}) || [];
			const hCol =
				hc?.data?.results.map((cc) => {
					return { ...cc, name: cc.collection.name };
				}) || [];
			const itm =
				d?.data?.results.map((dd) => {
					return { ...dd, name: dd.product.name };
				}) || [];
			const itmAll =
				dAll?.data?.results.map((dd) => {
					return {
						...dd,
						name: dd.name,
						product: {
							...dd,
						},
					};
				}) || [];
			let dt = [];

			if (fetchHomeCollections && fetchHomeProducts) {
				dt = [...hCol, ...itm];
			} else if (fetchCollections && fetchProducts) {
				dt = [...col, ...itmAll];
			} else if (categoryFilter === 'x' && fetchProducts) {
				dt = itmAll;
			} else {
				dt = itmAll;
			}

			return dt;
		}, [c, hc, d, dAll, fetchCollections, fetchHomeCollections, fetchHomeProducts, fetchProducts, categoryFilter]) || [];

	useEffect(() => {
		dispatch({ type: 'cart/setSelectedItem', payload: null });
	}, [dispatch]);

	return (
		<div className='app-container'>
			<div className='flex flex-col w-full h-full gap-4 p-4'>
				<UserHeader />
				<FilterOrder search={search} setSearch={setSearch} />
				<Categories />

				<div className='relative food-grids scrollable'>
					<ItemList
						itemsList={itemsList}
						onCallbackAfterInsert={() => {
							setSearch(null);
						}}
					/>
					{isStillFetching && <Loader />}
					{!isStillFetching && isDoneFetched && itemsList.length === 0 && (
						<span className='absolute top-1/2 left-1/2'>Sorry, no pre-orders available</span>
					)}
				</div>
			</div>
			<Navigation />
		</div>
	);
}
