import { Skeleton } from '@mui/material';
import { useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper/modules';
import { useQueries } from '@tanstack/react-query';
import { twMerge } from 'tailwind-merge';
import { useDispatch, useSelector } from 'react-redux';

import items from '@/api/items';

const { getCategories } = items();

export default function Categories() {
	const dispatch = useDispatch();
	const client = useSelector((state) => state.auth.user?.client?.id);
	const categoryFilter = useSelector((state) => state.cart.categoryFilter);
	const selectedOutlet = useSelector((state) => state.auth.selectedOutlet);

	const queryResult = useQueries({
		queries: [
			{
				queryKey: ['item-categories'],
				queryFn: () => getCategories({ client }),
				enabled: !!selectedOutlet,
				refetchOnWindowFocus: false,
			},
		],
	});

	const [a] = queryResult;

	const { data: categoryData } = a;

	const categories = useMemo(() => [{ id: 'x', name: 'All Products' }].concat([] || categoryData?.data?.results), [categoryData]);

    const isLoaded = queryResult.every((query) => query.isSuccess);

    const onFilterItems = (id) => {
		dispatch({ type: 'cart/setCategoryFilter', payload: id });
	};
	return (
		<div className='flex w-full gap-4'>
			{!isLoaded && (
				<div className='flex flex-1 w-full h-10 gap-4 overflow-hidden'>
					{[0, 1, 2, 3, 4, 5, 6, 7].map((a) => (
						<Skeleton key={a} className='w-full' />
					))}
				</div>
			)}
			{isLoaded && (
				<Swiper slidesPerView={'auto'} freeMode={true} modules={[FreeMode]} className='swiper-h'>
					{categories.map((f) => {
						return (
							<SwiperSlide
								key={f.id}
								className={twMerge(
									'px-4 py-2 rounded-sm w-auto h-12 text-nowrap cursor-pointer',
									// filter.length > 0 && filter.includes(f.id) ? 'bg-bz-green text-white' : 'bg-white border-bz-green border'
									f && categoryFilter === f.id ? 'bg-black text-white' : 'bg-white border-black border'
								)}
								onClick={() => onFilterItems(f.id)}
							>
								{f.name}
							</SwiperSlide>
						);
					})}
				</Swiper>
			)}
		</div>
	);
}
