import { useEffect, useRef, useState } from 'react';
import { Close, Search } from '@mui/icons-material';
import { ClickAwayListener } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import id from 'dayjs/locale/id';

export default function FilterOrder({ search, setSearch }) {
	const dispatch = useDispatch();
	const orderDate = useSelector((state) => state.cart.orderDate);

	const [showSearch, setShowSearch] = useState(false);

	const inputRef = useRef(null);

	const onFilter = (data) => {
		dispatch({ type: 'cart/setOrderDate', payload: data });
	};

	useEffect(() => {
		if (showSearch && inputRef.current) {
			inputRef.current.focus();
		}
	}, [showSearch]);

	let max = new Date();
	let weekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
	max.setTime(max.getTime() + weekInMilliseconds);

	return (
		<div className='relative flex items-center justify-between gap-2'>
			<span>Order Date</span>
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={id}>
				<DatePicker
					className='date-picker'
					label=''
					value={orderDate ? dayjs(orderDate) : null}
					onChange={(newValue) => onFilter(newValue)}
					closeOnSelect={true}
					slotProps={{
						field: { clearable: false },
					}}
					maxDate={dayjs(max)}
					format='DD/MM/YYYY'
				/>
			</LocalizationProvider>
			<button
				onClick={() => {
					setShowSearch(true);
				}}
			>
				<Search fontSize='medium' />
			</button>
			{showSearch && (
				<ClickAwayListener
					onClickAway={() => {
						if (!search) {
							setShowSearch(false);
						}
					}}
				>
					<div className='absolute w-[calc(100%_-_0rem)] search-dialog-container'>
						<div className='input-search-bar'>
							<Close
								className='absolute cursor-pointer top-2 left-1'
								sx={{ color: '#d6d2cf' }}
								onClick={() => {
									setShowSearch(false);
									setSearch(null);
								}}
							/>
							<input ref={inputRef} value={search} className='' onChange={(e) => setSearch(e.target.value)} />
							<Search className='absolute top-2 right-1' sx={{ color: '#d6d2cf' }} />
						</div>
					</div>
				</ClickAwayListener>
			)}
		</div>
	);
}
