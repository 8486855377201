import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { thunk } from 'redux-thunk';

import authSlice from './slices/authSlice';
import contentSlice from './slices/contentSlice';
import cartSlice from './slices/cartSlice';
import filterProductSlice from './slices/filterProductSlice';

const persistConfig = {
	key: 'root',
	storage,
	blacklist: ['cart'],
};

const rootReducer = combineReducers({
	auth: authSlice.reducer,
	content: contentSlice.reducer,
	cart: cartSlice.reducer,
	filterProduct: filterProductSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [
					FLUSH,
					REHYDRATE,
					PAUSE,
					PERSIST,
					PURGE,
					REGISTER,
					'filterProduct/setFilter',
					'cart/setOrderDate',
					'cart/setSelectedItem',
					'cart/setItems',
					'cart/updateItem',
					'cart/setItemQuantity',
				],
				ignoredActionPaths: ['filterProduct.products', 'cart.orderDate', 'cart.selectedItem', 'cart.items'],
				ignoredPaths: ['filterProduct.products', 'cart.orderDate', 'cart.selectedItem', 'cart.items'],
			},
		}).concat(thunk),
});

export default store;
export const persistor = persistStore(store);
