import axiosInstances from '@/helper/axiosInstances';
import qs from 'qs';

const baseURL = import.meta.env.VITE_API_URL;

export default function orders() {
	const { axiosInstance } = axiosInstances();

	async function getOrders(params, orderId = null) {
		const method = 'get';
		let url = `${baseURL}point-of-sale/orders/`;
		if (orderId) url += orderId;

		const response = await axiosInstance({
			method,
			url,
			params,
			paramsSerializer: (params) => {
				return qs.stringify(params, { arrayFormat: 'comma', encode: false});
			},
		});
		return response;
	}
	async function getOrderProducts(params, orderId = null) {
		const method = 'get';
		let url = `${baseURL}point-of-sale/order_products/`;
		if (orderId) url += orderId;

		const response = await axiosInstance({
			method,
			url,
			params,
			paramsSerializer: (params) => {
				return qs.stringify(params, { arrayFormat: 'comma', encode: false});
			},
		});
		return response;
	}

	return { getOrders, getOrderProducts };
}
