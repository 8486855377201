import axiosInstances from '@/helper/axiosInstances';
import qs from 'qs';

const baseURL = import.meta.env.VITE_API_URL;

export default function pos() {
	const { axiosInstance } = axiosInstances();

	async function getOutlets(params) {
		const method = 'get';
		const url = `${baseURL}point-of-sale/outlets/`;
		const response = await axiosInstance({
			method,
			url,
			params,
		});
		return response;
	}
	async function getOutlet(id) {
		const method = 'get';
		const url = `${baseURL}point-of-sale/outlets/${id}/`;
		const response = await axiosInstance({
			method,
			url,
		});
		return response;
	}
	async function getProducts(params) {
		const method = 'get';
		const url = `${baseURL}point-of-sale/products/`;
		const response = await axiosInstance({
			method,
			url,
			params,
			paramsSerializer: (params) => {
				return qs.stringify(params, { arrayFormat: 'repeat', encode: false});
			},
		});
		return response;
	}
	async function getDiscounts(params) {
		const method = 'get';
		const url = `${baseURL}point-of-sale/discounts/`;
		const response = await axiosInstance({
			method,
			url,
			params,
		});
		return response;
    }

	async function getAddOns(params) {
		const method = 'get';
		const url = `${baseURL}point-of-sale/add-ons/`;
		const response = await axiosInstance({
			method,
			url,
			params,
		});
		return response;
	}

	async function getPaymentTypes(params) {
		const method = 'get';
		const url = `${baseURL}point-of-sale/payment-types/`;
		const response = await axiosInstance({
			method,
			url,
			params,
		});
		return response;
	}

	return { getAddOns, getProducts, getDiscounts, getPaymentTypes, getOutlets, getOutlet };
}
