import { Home, Person, ShoppingCart, Summarize } from '@mui/icons-material';
import { route } from '@/constants/routeName';
import { Link, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { useSelector } from 'react-redux';

const navS = [
	{
		id: 1,
		icon: <Home fontSize='large' />,
		label: 'Home',
		to: '/',
	},
	{
		id: 2,
		icon: <Summarize fontSize='large' />,
		label: 'Orders',
		to: `/${route.orders}`,
	},
	{
		id: 3,
		icon: <ShoppingCart fontSize='large' />,
		label: 'My Cart',
		to: `/${route.cart}`,
	},
	{
		id: 4,
		icon: <Person fontSize='large' />,
		label: 'Account',
		to: `/${route.account}`,
	},
];

export default function Navigation() {
	const location = useLocation();
	const cartItems = useSelector((state) => state.cart.items);

	const { pathname } = location;

	return (
		<div className='fixed bottom-0 app-container'>
			<div className='flex justify-between w-full h-20 gap-4 p-4 bg-bz-default'>
				{navS.map((n) => {
					const activeClass = n.to === pathname ? 'text-black' : '';
					let showItems = false;
					if (cartItems.length && n.label === 'My Cart') {
						showItems = true;
					}
					return (
						<Link to={n.to} key={n.id} className={twMerge('relative flex flex-col items-center justify-center text-[#787878]', activeClass)}>
							{n.icon}
							{showItems && <span className='absolute right-0 text-red-500 top-1'>{cartItems.length}</span>}
							<span className='text-sm'>{n.label}</span>
						</Link>
					);
				})}
			</div>
		</div>
	);
}
