/* eslint-disable no-mixed-spaces-and-tabs */
import customers from '@/api/customers';
import Navigation from '@/components/Navigation/Navigation';
import ButtonBackHeader from '@/components/ui/Button/ButtonBackHeader';
import Loader from '@/components/ui/Loader/Loader';
import nf from '@/constants/nf';
import { ExpandMore, Summarize } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary, Pagination } from '@mui/material';
import UserHeader from '@/components/User/UserHeader';
import { twMerge } from 'tailwind-merge';

const { getTopUpsCustomer } = customers();

const pageSize = 20;
export default function TopUps() {
	const innerHeight = useSelector((state) => state.content.innerHeight);
	const selectedCustomer = useSelector((state) => state.auth.selectedCustomer);
	const paymentTypes = useSelector((state) => state.content.paymentTypes);

	const [page, setPage] = useState(1);

	const objParam = useMemo(() => {
		const obj = { customer: selectedCustomer?.id, type__in: ['Cash out', 'Deposit'], ordering: '-id', page_size: pageSize };
		if (page > 1) {
			obj.page = page;
		}
		return obj;
	}, [selectedCustomer, page]);

	const {
		data: d,
		isFetching,
		isFetched,
	} = useQuery({
		queryKey: ['my-topup-orders', objParam],
		enabled: !!selectedCustomer,
		queryFn: () => getTopUpsCustomer(objParam),
		refetchOnWindowFocus: false,
	});

	const topUpList =
		useMemo(() => {
			let obj = d?.data?.results
				? d.data.results.reduce((group, product) => {
						const { date } = product;
						const dateKey = dayjs(date).format('YYYY-MM-DD');
						group[dateKey] = group[dateKey] ?? [];
						group[dateKey].push(product);
						return group;
				  }, {})
				: [];
			if (typeof obj === 'object' && Object.keys(obj).length) {
				obj = Object.keys(obj)
					.map((o) => {
						return { date: o, topUps: obj[o] };
					})
					.sort((a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? -1 : 1));
			}
			return obj;
		}, [d]) || [];
	const topUpCount = useMemo(() => {
		return d?.data?.count ? Math.floor(d?.data?.count / pageSize) : 0;
	}, [d]);

	const handleChangePagination = (_, value) => {
		setPage(value);
	};

	return (
		<div style={{ minHeight: innerHeight }} className='app-container'>
			<div className='flex flex-col w-full h-full gap-4 p-4 pb-24'>
				<UserHeader />
				<div className='flex flex-col w-full gap-12'>
					<div className='relative'>
						<ButtonBackHeader className='top-0' />
						<div className='relative flex items-center justify-center'>
							<Summarize />
							<span className='ml-4 font-bold'>My Top-Up History</span>
						</div>
					</div>
					<div className='relative flex flex-col pb-24 min-h-24'>
						{isFetching && <Loader />}
						{isFetched && topUpList?.length > 0 && (
							<div className='flex flex-col gap-4'>
								<span className='pl-4 font-bold'>Top Up Date</span>
								{topUpList.map((t) => {
									const tItems = t.topUps.map((to) => {
										const isVoid = to.status === 'Void';
										const isDVoid = to.type === 'Deposit void';
										const isCashOut = to.type === 'Cash out';
										const voidStyle = isVoid ? 'line-through text-red-500' : '';

										return (
											<div key={to.id}>
												{/* <div className='carts items-top-ups'>
													<p className={twMerge('break-words', voidStyle)}>{to.number}</p>
													<span className={twMerge('break-words text-center', voidStyle)}>{to.type}</span>
													<span className={twMerge('break-words text-center', voidStyle)}>
														{paymentTypes.find((p) => p.id === to.payment_type?.id)?.name || ''}
													</span>
													<span className={twMerge('break-words text-right', voidStyle)}>{nf(Math.abs(+to.sales_amount) || 0)}</span>
												</div>
												<div className='flex flex-col'>
													{(isVoid || isDVoid) && to.note && <span className='pl-2 font-semibold'>Reason: {to.note}</span>}
												</div> */}
												<table className='w-full table-fixed'>
													<tbody>
														<tr>
															<td className={twMerge('break-words pr-2 align-top w-[30%]', voidStyle)}>{to.number}</td>
															<td className={twMerge('break-words pr-2 text-center align-top w-[20%]', voidStyle)}>{to.type}</td>
															<td className={twMerge('break-words pr-2 text-center align-top w-[23%]', voidStyle)}>
																{paymentTypes.find((p) => p.id === to.payment_type?.id)?.name || ''}
															</td>
															<td className={twMerge('break-words text-right align-top w-[27%]', voidStyle)}>
																{nf(Math.abs(+to.sales_amount) * (isCashOut ? -1 : 1) || 0)}
															</td>
														</tr>
													</tbody>
												</table>
												{(isVoid || isDVoid) && to.note && <span className='w-full pl-2 text-sm italic'>Reason: {to.note}</span>}
											</div>
										);
									});
									const date = dayjs(new Date(t.date)).format('dddd, DD MMMM YYYY');
									return (
										<Accordion key={t.date}>
											<AccordionSummary expandIcon={<ExpandMore />} aria-controls='panel1-content' id='panel1-header'>
												<span className='font-bold'>{date}</span>
											</AccordionSummary>
											<AccordionDetails>
												<div className='flex flex-col gap-2'>
													<div className='flex flex-col gap-1'>{tItems}</div>
												</div>
											</AccordionDetails>
										</Accordion>
									);
								})}
							</div>
						)}
						{isFetched && ((typeof orderList === 'object' && Object.keys(topUpList).length === 0) || topUpList?.length === 0) && (
							<span className='w-full text-center'>No top up found</span>
						)}
						{isFetched && <Pagination className='mt-4 ml-auto' count={topUpCount} page={page} onChange={handleChangePagination} />}
					</div>
				</div>
			</div>
			<Navigation />
		</div>
	);
}
