import nf from '@/constants/nf';
import { Person } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

export default function UserHeader() {
	const user = useSelector((state) => state.auth.user);
	const selectedCustomer = useSelector((state) => state.auth.selectedCustomer);
	const selectedOutlet = useSelector((state) => state.auth.selectedOutlet);

	return (
		<div>
			<div className='flex gap-2 bg-bz-default'>
				<div className='relative h-16 border border-black w-14'>
					{user.avatar ? (
						<img src={user.avatar} alt={user.name} className='object-cover w-full h-full' />
					) : (
						<div className='w-full h-full text-[48px] flex'>
							<Person fontSize='inherit' className='m-auto' />
						</div>
					)}
				</div>
				<div className='flex flex-col py-1 text-sm'>
					<span className='font-bold'>{user.name}</span>
					<span className='text-xs'>
						{selectedOutlet?.name}, {selectedCustomer?.member?.on_list_attributes?.class}
					</span>
					<span className={twMerge('text-xs font-bold', Math.sign(+selectedCustomer?.balance) < 0 ? 'text-red-500' : '')}>
						Deposit: {nf(+selectedCustomer?.balance || 0)}
					</span>
				</div>
			</div>
		</div>
	);
}
