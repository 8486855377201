import nf from '@/constants/nf';
import { Close } from '@mui/icons-material';
import { twMerge } from 'tailwind-merge';

export default function LabelCartItem({ item, value1, value2, value3, config = { enableCancel: false, from: 'cart', onCancel: () => {} } }) {
	const { enableCancel, from, onCancel } = config;
	const voidStyle = (item?.is_void && !item?.isFullVoid) || item?.isCancel ? 'line-through' : '';
	return (
		<div className='carts items'>
			<span className={twMerge(voidStyle, 'break-words font-bold')}>{value1}</span>
			<div className='flex flex-wrap gap-2'>
				<div className='flex gap-2 items-center'>
					<span className={twMerge('text-left font-bold', voidStyle)}>
						{value2}
						{from === 'order' && enableCancel && <Close onClick={onCancel} className='ml-1 cursor-pointer' style={{ color: 'var(--error)' }} />}
					</span>
				</div>
				{voidStyle && !item?.isFullVoid && <span className='font-bold text-red-500'>Canceled</span>}
			</div>
			<span className={twMerge('text-right', voidStyle)}>{nf(value3)}</span>
		</div>
	);
}

export const LabelCartItemChild = ({ label, value }) => {
	return (
		<div className='carts items-child'>
			<span className='pl-16 font-bold text-left'>{label}</span>
			<span className='text-right'>{value}</span>
		</div>
	);
};

export const LabelCartItemsChild = ({ label, value }) => {
	return (
		<div className='carts items-child-l'>
			<span></span>
			<span className='text-left'>{label}</span>
			<span className='text-right'>{value}</span>
		</div>
	);
};

export const LabelCartItemAdd = ({ label, value }) => {
	return (
		<div className='carts items'>
			<span></span>
			<span className='font-bold text-left'>{label}</span>
			<span className='text-right'>{value}</span>
		</div>
	);
};

export const LabelNotes = ({ children, notes }) => {
	return (
		<div className='carts notes'>
			<span />
			{children}
			<span className='italic text-left'>{notes}</span>
		</div>
	);
};
