import getTokenBearer from '@/hooks/getTokenBearer';
import axios from 'axios';

const baseURL = import.meta.env.VITE_API_URL;
const mode = import.meta.env.VITE_MODE;
const axiosInstance = axios.create({
	baseURL,
	headers: { 'Content-Type': 'multipart/form-data' },
});

function authSSO() {
	async function signInSSO(data) {
		const method = 'post';
		const url = `${baseURL}login/`;
		const response = await axiosInstance({
			method,
			url,
			data,
		});
		return response;
	}
	async function signOutSSO(token) {
		const headers = {
			Authorization: `${getTokenBearer()} ${token}`,
			'Content-Type': 'multipart/form-data',
		};
		const response = await axios.post(
			`${baseURL}logout/`,
			{
				token,
				client_id: 'W08vGPLmig1Jyzktk8dsnIjsJNPfcbkr1CbRcZVc',
				client_secret:
					'WaBVvKswzHJ8vzlaIvPqVvAJrpNlbYWEaZj8IwxPkLLJCTPGtQuivQap17679bgZeP8J3zzK2XlDZN86D3xCP5PtRL5aJcqc1Z3WVmTY4DgcOoTmFW3iLjQhqIVUJrVj',
			},
			{ headers }
		);
		return response;
	}
	async function signin(data) {
		const headers = {
			Authorization: `basic ${btoa(`${data.username}:${data.password}`)}`,
			'Referer': 'https://njis.jungletoppings.com/',
			'Content-Type': 'application/json',
		};
		if (mode === 'production') {
			delete headers.Referer
		}
		const response = await axios.post(`${baseURL}customer-login/`, {}, { headers });
		return response;
	}
	async function signout(token) {
		const headers = {
			Authorization: `${getTokenBearer()} ${token}`,
			'Content-Type': 'application/json',
		};
		const response = await axios.post(`${baseURL}customer-logout/`, { token }, { headers });
		return response;
	}

	return {
		signin,
		signout,
		signInSSO,
		signOutSSO,
	};
}

export default authSSO;
