import axiosInstances from "@/helper/axiosInstances";

const baseURL = import.meta.env.VITE_API_URL;

export default function items() {
  const { axiosInstance } = axiosInstances();

  async function getItems(params) {
    const method = 'get';
    const url = `${baseURL}point-of-sale/outlet_products/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getCollections(params) {
    const method = 'get';
    const url = `${baseURL}point-of-sale/outlet_collections/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getInnerCollections(params) {
    const method = 'get';
    const url = `${baseURL}point-of-sale/collections/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getHomeCollections(params) {
    const method = 'get';
    const url = `${baseURL}point-of-sale/home_collections/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getCategories(params) {
    const method = 'get';
    const url = `${baseURL}product-management/categories/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getPaymentTypes(params) {
    const method = 'get';
    const url = `${baseURL}point-of-sale/payment_types/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getServingTimes(params) {
    const method = 'get';
    const url = `${baseURL}point-of-sale/serving_times/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    }).catch((e) => {
      return e.response
    })
    return response;
  }

  return { getItems, getCollections, getInnerCollections, getHomeCollections, getCategories, getPaymentTypes, getServingTimes };
}
