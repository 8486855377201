import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { useEffect } from 'react';

import App from './App.jsx';
import store, { persistor } from './store';

import { fallbackErrorRender } from './components/ErrorBoundary/ErrorBoundary';

import './assets/css/app-style.scss';
import './assets/css/tailwind.css';
import './index.css';
import 'swiper/css';
import { PersistGate } from 'redux-persist/integration/react';

const mode = import.meta.env.VITE_MODE;

mode === 'production' &&
	!['localhost', '127.0.0.1'].includes(window.location.hostname) &&
	Sentry.init({
		dsn: import.meta.env.VITE_SENTRY_KEY,
		environment: mode,
		integrations: [
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),
			Sentry.replayIntegration(),
		],
		// Tracing
		tracesSampleRate: 1.0,
		// Session Replay
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		ignoreErrors: [/Each child/i, /validateDOMNesting/i, /NaN/i, /recognize/i, /Maximum call/i],
	});

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
	<ErrorBoundary fallbackRender={fallbackErrorRender}>
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				{/* <React.StrictMode> */}
				<App />
				{/* </React.StrictMode> */}
			</PersistGate>
		</Provider>
	</ErrorBoundary>
);
