import { Box, Modal } from '@mui/material';

import ModalStyle from '@/constants/ModalStyle';
import { wTextST } from '@/constants/wTextST';

export default function WarningServingTime(props) {
	const { open, onClose, from = 'item', onClearCart = () => {} } = props;

	return (
		<Modal open={open} onClose={onClose} aria-labelledby='modal-warning' aria-describedby='modal-warning' className='w-full'>
			<Box sx={{ ...ModalStyle, top: '25%' }} className='flex flex-col gap-4 w-[90%] md:w-[360px]'>
				<span className='font-bold text-center text-md'>Warning</span>
				<div className='flex flex-col space-y-4'>
					<span className=''>{wTextST}</span>
					{from === 'cart' && <span>Do you want to clear the cart?</span>}

					<div className='flex gap-4 ml-auto '>
						{from === 'cart' && (
							<button className='mr-0 bg-black btn-actions min-w-max' onClick={onClearCart}>
								Yes
							</button>
						)}
						<button className='mr-0 bg-black btn-actions min-w-max' onClick={onClose}>
							{from === 'cart' ? 'Cancel' : 'Close'}
						</button>
					</div>
				</div>
			</Box>
		</Modal>
	);
}
