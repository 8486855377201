import { Navigate } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import AuthProvider from '@/components/AuthProvider';
import Layout from '@/components/Layout/Layout';
import NotFound from '@/pages/NotFound';
import Home from '@/pages/Home/Home';
import Orders from '@/pages/Orders/Orders';
import OrderDetail from '@/pages/Orders/OrderDetail';
import Account from '@/pages/Account/Account';
import Cart from '@/pages/Cart/Cart';
import CartItem from '@/pages/Cart/CartItem';
import Item from '@/pages/Item/Item';
import UpdatePassword from '@/pages/Account/UpdatePassword';
import { route } from '@/constants/routeName';
import TopUps from '@/pages/Account/TopUps';
import { ErrorElement } from '@/components/ErrorBoundary/ErrorElement';
// import Notification from '@/pages/Notification';


const authenticatedRoutes = [
	{
		path: '/',
		element: (
			<ProtectedRoute>
				<AuthProvider>
					<Layout />
				</AuthProvider>
			</ProtectedRoute>
		),
		children: [
			{
				path: '/login',
				element: <Navigate to='/' />,
			},
			{
				path: '',
				element: <Home />,
			},

			{
				path: '*',
				element: <NotFound />,
			},
			{
				path: `/${route.orders}`,
				children: [
					{ index: true, element: <Orders /> },
					{
						path: `/${route.orders}/:tabs`,
						element: <Orders />,
					},
					{
						path: `/${route.ordersDetail}/:id`,
						element: <OrderDetail />,
					},
				],
			},
			{
				path: `/${route.account}`,
				children: [
					{ index: true, element: <Account /> },
					{
						path: `/${route.accountPassword}/`,
						element: <UpdatePassword />,
					},
					{
						path: `/${route.accountTopUps}/`,
						element: <TopUps />,
					},
				],
			},
			{
				path: `/${route.item}`,
				element: <Item />,
				children: [
					{ index: true, element: <Item /> },
					{
						path: `/${route.item}/:name`,
						element: <Item />,
					},
				],
			},
			{
				path: `/${route.cart}`,
				children: [
					{ index: true, element: <Cart /> },
					{
						path: `/${route.cartItem}/:uid`,
						element: <CartItem />,
					},
				],
			},
			// {
			// 	path: `/${route.notification}`,
			// 	element: <Notification />,
			// },
		],
		errorElement: <ErrorElement />
	},
];

export default authenticatedRoutes;
