import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { twMerge } from 'tailwind-merge';

export default function QuantityControl({ item = {}, amount, disabled, changeAmount, config = { className: '' } }) {
	return (
		<div className={twMerge('flex items-center justify-center gap-2', config.className)}>
			<button disabled={disabled} onClick={() => changeAmount('minus', item)}>
				<RemoveCircle />
			</button>
			{amount}
			<button onClick={() => changeAmount('plus', item)}>
				<AddCircle />
			</button>
		</div>
	);
}
