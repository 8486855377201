/* eslint-disable no-unused-vars */
import ButtonBackHeader from '@/components/ui/Button/ButtonBackHeader';
import nf from '@/constants/nf';
import { AddCircle, ArrowBack, RemoveCircle } from '@mui/icons-material';
import { Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { v4 as uuidv4 } from 'uuid';
import QuantityControl from '../Item/QuantityControl';

const AddOnModifier = ({ data, selected, onCheck }) => {
	return (
		<div className='carts add-ons'>
			<span className={twMerge('')}>{data.name}</span>
			<span className={twMerge('')}>{data.price ? `+${nf(data.price)}` : 'Free'}</span>
			<Checkbox className='ck-table' checked={!!selected} onChange={(e) => onCheck(data)} inputProps={{ 'aria-label': 'controlled' }} />
		</div>
	);
};

export default function CartItem() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const item = useSelector((state) => state.cart.selectedItem);
	const servingTimes = useSelector((state) => state.content.servingTimes);
	const orderDate = useSelector((state) => state.cart.orderDate);

	const [addOns, setAddOns] = useState(item?.add_ons?.map((a) => a.add_on) || []);
	const [modifiers, setModifiers] = useState(item?.modifiers?.map((a) => a.modifier) || []);
	const [servingTime, setServingTime] = useState(item?.serving_time?.id || '');
	const [amount, setAmount] = useState(item?.quantity || 1);

	const add_ons = item?.add_ons?.map((a) => a.add_on);
	const modifier = item?.modifiers?.map((a) => a.modifier);

	const isA = add_ons && add_ons?.length > 0;
	const isM = modifier && modifier?.length > 0;

	const onCheck = (key, id, checked) => {
		dispatch({ type: 'cart/setSelectedItemKey', payload: { key, id, checked } });
	};
	const onAddOns = (itm) => {
		if (addOns.find((a) => a.id === itm.id)) {
			setAddOns((prevState) => prevState.filter((a) => a.id !== itm.id));
		} else {
			setAddOns((prevState) => prevState.concat([itm]));
		}
	};
	const onAddModifiers = (itm) => {
		if (modifiers.find((a) => a.id === itm.id)) {
			setModifiers((prevState) => prevState.filter((a) => a.id !== itm.id));
		} else {
			setModifiers((prevState) => prevState.concat([itm]));
		}
	};
	const handleChangeServing = (event) => {
		setServingTime(event.target.value);
	};
	const changeAmount = (key) => {
		setAmount((prevState) => {
			if (key === 'plus') {
				return prevState + 1;
			}
			if (prevState === 1) return 1;
			return prevState - 1;
		});
	};
	const getAddOnsModifier = () => {
		const newAddOns = addOns.map((a) => {
			return { id: uuidv4(), add_on: { ...a } };
		});
		const newModifiers = modifiers.map((a) => {
			return { id: uuidv4(), modifier: { ...a } };
		});
		return { addOns: newAddOns, modifiers: newModifiers };
	};
	const onAddToCart = () => {
		const { addOns, modifiers } = getAddOnsModifier();
		dispatch({
			type: 'cart/updateItem',
			payload: {
				...item,
				quantity: amount,
				is_void: false,
				modifiers: modifiers,
				add_ons: addOns,
				serving_time: servingTime ? servingTimes.find((s) => +s.id === +servingTime) : null,
				date: orderDate,
			},
		});
		navigate(-1);
	};

	let totalPrice = (+item?.selling_price || 0) * amount;
	if (addOns.length) {
		totalPrice += addOns.reduce((a, b) => +a + +b.price * amount, 0);
	}

	useEffect(() => {
		if (!item) {
			navigate('/')
		}
	}, [item, navigate])

	return (
		<div className='app-container'>
			<div className='relative flex flex-col flex-1 gap-6 p-4'>
				<ButtonBackHeader />
				<div className='relative flex flex-col items-center justify-center'>
					<span className='font-bold'>{item?.name}</span>
					<div>
						<span className='font-bold'>{nf(+item?.selling_price)}</span>
					</div>
				</div>
				{(isA || isM) && (
					<div className='flex flex-col'>
						{isA &&
							add_ons.map((a) => {
								const selected = addOns.find((m) => m.id === a.id);
								return <AddOnModifier key={`add-ons-${a?.id}-${item?.uid}`} data={a} onCheck={onAddOns} selected={selected} />;
							})}
						{isM &&
							modifier.map((m) => {
								const selected = modifiers.find((a) => a.id === m.id);
								return <AddOnModifier key={`modifier-${m?.id}-${item?.uid}`} data={m} onCheck={onAddModifiers} selected={selected} />;
							})}
					</div>
				)}
				<FormControl>
					<FormLabel id='demo-controlled-radio-buttons-group'>Serving Times</FormLabel>
					<RadioGroup
						aria-labelledby='demo-controlled-radio-buttons-group'
						name='controlled-radio-buttons-group'
						value={servingTime}
						onChange={handleChangeServing}
					>
						{servingTimes?.map((s) => {
							return <FormControlLabel key={s.id} value={s.id} control={<Radio />} label={s.name} />;
						})}
					</RadioGroup>
				</FormControl>
				<div className='flex justify-between'>
					<span>Purchase Amount</span>
					<QuantityControl amount={amount} changeAmount={changeAmount} disabled={amount === 0} />
				</div>
				<div className='flex justify-between'>
					<span>Order Date</span>
					<span>{dayjs(item?.date).format('dddd, DD MMMM YYYY')}</span>
				</div>
				<button className='btn-actions' onClick={onAddToCart} disabled={amount === 0 || !servingTime}>
					Update Cart
					<span className='ml-1'>{nf(totalPrice)}</span>
				</button>
			</div>
		</div>
	);
}
