import { wTextST } from '@/constants/wTextST';
import getRemainingTime from '@/helper/getRemainingTime';
import { memo, useEffect, useState } from 'react';

function pad(number, width) {
	return new Array(+width + 1 - (number + '').length).join('0') + number;
}

const CountdownServing = memo(function CountdownServing({ expTime, whenExpired = () => {}, isDisable }) {
	const [expiryTime, setExpiryTime] = useState(expTime);
	const [countdownTime, setCountdownTime] = useState({
		cD: '',
		cH: 0,
		cM: 0,
		cS: 0,
	});

	const countdownTimer = () => {
		const timeInterval = setInterval(() => {
			const remainingDayTime = getRemainingTime(expTime, { setCountdownTime, timeInterval, setExpiryTime, clearInterval, whenExpired });

			if (remainingDayTime < 0) {
				clearInterval(timeInterval);
				setExpiryTime(false);
				if (!isDisable) {
					whenExpired(true);
				}
			}
			// const countdownDateTime = new Date(expTime).getTime();
			// const currentTime = new Date().getTime();
			// const remainingDayTime = countdownDateTime - currentTime;
			// const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
			// const totalHours = Math.floor((remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			// const totalMinutes = Math.floor((remainingDayTime % (1000 * 60 * 60)) / (1000 * 60));
			// const totalSeconds = Math.floor((remainingDayTime % (1000 * 60)) / 1000);

			// const runningCountdownTime = {
			// 	cD: Math.abs(totalDays),
			// 	cH: Math.abs(totalHours),
			// 	cM: Math.abs(totalMinutes),
			// 	cS: Math.abs(totalSeconds),
			// };

			// setCountdownTime(runningCountdownTime);

			// if (remainingDayTime < 0) {
			// 	clearInterval(timeInterval);
			// 	setExpiryTime(false);
			// 	whenExpired(true);
			// }
		}, 1000);
	};

	useEffect(() => {
		if (expiryTime) {
			const timeoutId = countdownTimer();
			return () => clearInterval(timeoutId);
		}
	});

	const { cH, cM, cS, cD } = countdownTime;
	const displayDay = !!cD && cH > 23;
	return (
		<div className='flex flex-wrap items-center justify-end w-full gap-1'>
			{(!expiryTime || isDisable) && (
				<span className='text-red-300'>
					{wTextST}
				</span>
			)}
			{expiryTime && cH < 12 && cS > 0 && (
				<span>
					Ordering deadline in {displayDay ? `${cD}`(cD > 1 ? 'days' : 'day') : ''} {pad(cH, 2)}:{pad(cM, 2)}:{pad(cS, 2)}
				</span>
			)}
		</div>
	);
});
export default CountdownServing;
