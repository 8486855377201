import { useCallback, useEffect } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import JTHelmet from '../JTHelmet';
import { getDiscounts, getOutlet, getPaymentTypes, getServingTimes } from '@/store/slices/contentSlice';
import { getCustomer } from '@/store/slices/authSlice';
import usePageTracking from '../../hooks/usePageTracking';
import { getDiscountCustomer } from '@/store/slices/cartSlice';

export default function Layout() {
	usePageTracking();

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const observedElementRef = useRef(null);
	const reFetch = useRef(null);

	const client = useSelector((state) => state.auth.user?.client?.id);
	const selectedOutlet = useSelector((state) => state.auth.selectedOutlet);
	const paymentTypes = useSelector((state) => state.content.paymentTypes);
	// const servingTimes = useSelector((state) => state.content.servingTimes);
	const discounts = useSelector((state) => state.content.discounts);
	const selectedCustomer = useSelector((state) => state.auth.selectedCustomer);

	useEffect(() => {
		if (!selectedOutlet) {
			dispatch({ type: 'auth/setLogout' });
			dispatch({ type: 'cart/resetItems' });
			dispatch({ type: 'content/resetOutlet', payload: false });
			dispatch({ type: 'content/resetFetchAPI', payload: false });
			navigate('/');
		}
	});
	const errRequest = useSelector((state) => state.content.errRequest);

	const onResize = useCallback(() => {
		if (observedElementRef.current) dispatch({ type: 'content/setInner', payload: window.innerHeight });
	}, [dispatch]);

	useEffect(() => {
		window.addEventListener('resize', onResize);
		onResize();
		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, [onResize]);

	useEffect(() => {
		if (selectedOutlet) {
			dispatch(getOutlet(selectedOutlet.id));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (selectedOutlet && !paymentTypes) {
			dispatch(getPaymentTypes({ outlet: selectedOutlet.id, max_size: true, ordering: 'id' }));
		}
	}, [selectedOutlet, paymentTypes, client, dispatch]);

	useEffect(() => {
		if (selectedOutlet) {
			dispatch(getServingTimes({ outlet: selectedOutlet?.id, max_size: true }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (selectedOutlet && !discounts) {
			dispatch(getDiscounts({ outlet: selectedOutlet?.id, max_size: true }));
		}
	}, [selectedOutlet, discounts, client, dispatch]);

	useEffect(() => {
		if (selectedCustomer && !reFetch.current) {
			reFetch.current = true;
			dispatch(getCustomer({ params: {}, id: selectedCustomer.id }));
		}
	});

	useEffect(() => {
		if (selectedCustomer) {
			dispatch(getDiscountCustomer());
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (errRequest && selectedOutlet) {
			// dispatch({ type: 'content/clearError' });
			// dispatch({ type: 'auth/setLogout' });
			// dispatch({ type: 'cart/resetItems' });
			// dispatch({ type: 'content/resetOutlet', payload: false });
			// dispatch({ type: 'content/resetFetchAPI', payload: false });
		}
	}, [errRequest, selectedOutlet, dispatch]);

	return (
		<>
			<JTHelmet />
			<div ref={observedElementRef} className='layout'>
				<Outlet />
			</div>
		</>
	);
}
