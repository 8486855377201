import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';

import Navigation from '@/components/Navigation/Navigation';
import UserHeader from '@/components/User/UserHeader';
import authSSO from '@/api/authSSO';
import { ChevronRight } from '@mui/icons-material';
import { route } from '@/constants/routeName';

const AccContent = ({ label, value }) => {
	return (
		<div className='flex justify-between'>
			<span>{label}</span>
			<span>{value}</span>
		</div>
	);
};

const { signout } = authSSO();

export default function Account() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const innerHeight = useSelector((state) => state.content.innerHeight);
	const c = useSelector((state) => state.auth.selectedCustomer);
	const key = useSelector((state) => state.auth.key);
	const [loading, setLoading] = useState(false);

	const onSignOut = () => {
		setLoading(true);
		signout(key)
			.then(() => {
				dispatch({ type: 'auth/setLogout' });
				dispatch({ type: 'cart/resetItems' });
				dispatch({ type: 'content/resetOutlet', payload: false });
				dispatch({ type: 'content/resetFetchAPI', payload: false });
				navigate('/');
			})
			.catch((err) => {
				toast.error(err.response?.data?.detail || 'Error');
				setLoading(false);
			});
	};

	return (
		<div style={{ minHeight: innerHeight }} className='app-container'>
			<div className='flex flex-col w-full h-full gap-6 p-4'>
				<UserHeader />
				<div className='flex flex-col gap-4'>
					<AccContent label='Student ID' value={c.member?.on_list_attributes?.student_id} />
					<AccContent label='Class' value={c.member?.on_list_attributes?.class} />
					<div className='flex justify-between'>
						<span>Password</span>
						<Link to={`/${route.accountPassword}`} className='flex items-center justify-center'>
							<span className='mt-1'>* * * *</span>
							<ChevronRight />
						</Link>
					</div>
					<AccContent label='Dietary preferences' value={c.member?.on_list_attributes.dietary_preferences?.join(', ')} />
					<div>
						<AccContent label='Contact' value={c.member?.on_list_attributes.contact?.name} />
						<AccContent label='' value={c.member?.on_list_attributes.contact?.phone} />
					</div>
				</div>
				<Link to={`/${route.accountTopUps}`} className='btn-actions'>
					My Top-Up History
				</Link>
				<button className='btn-actions' onClick={onSignOut}>
					Sign Out {loading && <CircularProgress className='ml-2' size={18} sx={{ color: 'var(--white)' }} />}
				</button>
			</div>
			<Navigation />
		</div>
	);
}
