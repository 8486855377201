import items from '@/api/items';
import pos from '@/api/pos';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export const getOutlet = createAsyncThunk('content/setOutlet', async (params) => {
	const { getOutlet } = pos();
	const response = await getOutlet(params);
	return response.data;
});

export const getPaymentTypes = createAsyncThunk('content/setPaymentTypes', async (params) => {
	const { getPaymentTypes } = items();
	const response = await getPaymentTypes(params);
	return response.data;
});

export const getServingTimes = createAsyncThunk('content/setServingTimes', async (params) => {
	const { getServingTimes } = items();
	const response = await getServingTimes(params);
	if (response.status === 401) {
		return 401
	}
	return response.data;
});

export const getDiscounts = createAsyncThunk('content/setDiscounts', async (params) => {
	const { getDiscounts } = pos();
	const response = await getDiscounts(params);
	return response.data;
});

const contentSlice = createSlice({
	name: 'content',
	initialState: {
		innerHeight: window.innerHeight,
		innerFoodHeight: window.innerHeight - 96 - 32 - 32,
		innerContainerHeight: window.innerHeight - 32,

		outlet: null,
		utcOffset: 0,
		paymentTypes: null,
		paymentTypesTopUp: null,
		servingTimes: null,
		objServingTimes: null,
		discounts: null,
		payType: {},
		errRequest: null,
	},
	reducers: {
		setInner(state, action) {
			const currState = state;
			const data = action.payload;
			const newInnerH = data;
			currState.innerHeight = newInnerH;
			currState.innerFoodHeight = newInnerH - 96 - 32 - 32;
			currState.innerContainerHeight = newInnerH - 32;
		},
		setOutlet(state, action) {
			const currState = state;
			const data = action.payload;
			currState.selectedOutlet = data.value;
			Cookies.set('jt-o', JSON.stringify(data.value));
		},
		resetOutlet(state, action) {
			const currState = state;
			const data = action.payload;
			currState.selectedOutlet = data.value;
			Cookies.remove('jt-o');
			// Cookies.remove('jt-sh');
		},
		setShift(state, action) {
			const currState = state;
			const data = action.payload;
			if (data) {
				currState.selectedShift = data;
				Cookies.set('jt-sh', JSON.stringify(data));
			} else {
				currState.selectedShift = false;
				Cookies.remove('jt-sh');
			}
		},
		resetFetchAPI(state) {
			const currState = state;
			currState.outlet = null;
			currState.utcOffset = 0;
			currState.paymentTypes = null;
			currState.servingTimes = null;
			currState.objServingTimes = null;
			currState.payType = {};
		},
		clearError(state) {
			const currState = state;
			currState.errRequest = null;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getOutlet.fulfilled, (state, action) => {
			const currState = state;
			currState.outlet = action.payload;
			currState.utcOffset = action.payload?.location?.utc_offset
				? action.payload?.location?.utc_offset || 0
				: action.payload?.department?.locations?.length
				? action.payload?.department?.locations[0]?.utc_offset || 0
				: 0;
		});
		builder.addCase(getPaymentTypes.fulfilled, (state, action) => {
			const currState = state;
			const data = action.payload?.results;
			const paymentTypes = data;
			const paymentTypesTopUp = data.filter((d) => d.allow_in_top_up);
			const paymentOpen = paymentTypes.find((p) => p.name.toLowerCase().includes('open'))?.id;
			const paymentDeposit = paymentTypes.find((p) => p.name.toLowerCase().includes('deposit'))?.id;
			const paymentVoid = paymentTypes.find((p) => p.name.toLowerCase().includes('void'))?.id;
			const paymentCash = paymentTypes.find((p) => p.name.toLowerCase().includes('cash'))?.id;
			const paymentTodays = paymentTypes.filter((p) => !p.name.toLowerCase().includes('open')).map((v) => v.id);
			const paymentCancel = paymentTypes.find((p) => p.name.toLowerCase().includes('cancel'))?.id;
			currState.payType = {
				open: paymentOpen,
				deposit: paymentDeposit,
				void: paymentVoid,
				cash: paymentCash,
				today: paymentTodays,
				cancel: paymentCancel,
			};
			currState.paymentTypes = paymentTypes;
			currState.paymentTypesTopUp = paymentTypesTopUp;
		});
		builder.addCase(getServingTimes.fulfilled, (state, action) => {
			const currState = state;
			if (action.payload === 401) {
				currState.errRequest = action.payload
			} else {
				const data = action.payload?.results;
				currState.servingTimes = data;
				const objS = {}
				if (data.length) {
					data.forEach((s) => {
						objS[s.id] = s
					});
					currState.objServingTimes = objS;
				}
			}
		});
		builder.addCase(getDiscounts.fulfilled, (state, action) => {
			const currState = state;
			currState.discounts = action.payload?.results;
		});
	},
});

export const contentActions = contentSlice.actions;
export default contentSlice;
