import { add, getDate, getMonth, getYear, sub } from 'date-fns';

export function getCutOffTime(item, orderDate, utcOffset) {
    const cutOffTime = item.order_cut_off_duration || '00:00:00';
	const cTime = cutOffTime?.split(':');

	const cutH = +cTime[0]
	const cutM = +cTime[1]
	const cutS = +cTime[2]

	const startTime = item.start_time || '00:00:00';
	const cSTime = startTime.split(':');
	const cSH = +cSTime[0]
	const cSM = +cSTime[1]
	const csS = +cSTime[2]

	const orderDt = new Date(orderDate)
	const orderUTC = orderDt.getTimezoneOffset()
	const orderY = getYear(orderDt)
	const orderM = getMonth(orderDt)
	const orderD = getDate(orderDt)

	const utcDiff = (orderUTC * -1) - utcOffset


	const oD = new Date(orderY, orderM, orderD)
	const oT = add(new Date(oD), { hours: cSH, minutes: cSM + utcDiff, seconds: cutS })
	const sT = add(new Date(oD), { hours: cSH, minutes: cSM, seconds: csS })

	const oCutOff = sub(new Date(oT), { hours: cutH, minutes: cutM, seconds: csS })
    return {oCutOff, sT};
}
