import { ArrowBack, Image } from '@mui/icons-material';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';
import { v4 as uuidv4 } from 'uuid';

import nf from '@/constants/nf';
import { useNavigate } from 'react-router-dom';
import { route } from '@/constants/routeName';
import slugify from 'slugify';
import dayjs from 'dayjs';
import { isAfter, isSameDay } from 'date-fns';

export default function ItemList(props) {

	const { itemsList } = props;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const history = useSelector((state) => state.cart.historyCollection);
	const orderDate = useSelector((state) => state.cart.orderDate);

	const dateDiff = useMemo(() => {
		const oD = dayjs(orderDate).format('YYYY-MM-DD')
		const today = dayjs(new Date()).format('YYYY-MM-DD')
		return isSameDay(oD, today) || isAfter(oD, today)
	}, [orderDate])

	const onClickItem = (type, data) => {
		if (!dateDiff && type === 'item') return;
		if (type === 'item') {
			dispatch({ type: 'cart/setSelectedItem', payload: data });
			navigate(`/${route.item}/${slugify(data.name.toLowerCase(), '-')}`);
		}
		if (type === 'collection') {
			dispatch({ type: 'cart/setHistoryCollection', payload: { type: 'add', data } });
		}
	};

	const onRemoveHistory = () => {
		dispatch({ type: 'cart/setHistoryCollection', payload: { type: 'remove' } });
	};

	return (
		<>
			{history?.length > 0 && (
				<button className='food-cards' onClick={onRemoveHistory}>
					<div className='no-image'>
						<ArrowBack style={{ color: 'black' }} />
					</div>
					<div className='collection'>
						<span className={twMerge('text-center text-base font-bold')}>Back</span>
					</div>
				</button>
			)}
			{itemsList.map((f) => {
				const isItems = !('collection' in f);
				const objItems = isItems ? f.product : f.collection;
				let data = { ...objItems };
				if (isItems) {
					data.price = +data.selling_price;
					data.uid = uuidv4();
				}

				return (
					<button
						key={`${data.id}-${data.name}`}
						className={twMerge('food-cards', !dateDiff && isItems ? 'disabled' : '')}
						onClick={() => onClickItem(isItems ? 'item' : 'collection', data)}
					>
						{data.thumbnail ? (
							<img src={data.thumbnail} alt={data.name} loading='eager' />
						) : (
							<div className='no-image'>
								<Image />
							</div>
						)}
						<div className={twMerge(!isItems ? 'collection' : '')}>
							<span className={twMerge('text-center font-bold', !isItems ? 'text-sm' : 'text-sm')}>{data.name}</span>
							{isItems && <span className='text-sm font-bold text-center'>{nf(data.price)}</span>}
						</div>
					</button>
				);
			})}
		</>
	);
}
