/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-unused-vars */
import Navigation from '@/components/Navigation/Navigation';
import LabelCartItem, { LabelCartItemAdd, LabelCartItemChild, LabelNotes } from '@/components/ui/Label/LabelCartItem';
import Loader from '@/components/ui/Loader/Loader';
import UserHeader from '@/components/User/UserHeader';
import nf from '@/constants/nf';
import usePatch from '@/hooks/request/usePatch';
import { getCustomer } from '@/store/slices/authSlice';
import { generateDiscountValue, getEverything, getSubTotal } from '@/store/slices/cartSlice';

import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import QuantityControl from '../Item/QuantityControl';
import { route } from '@/constants/routeName';

import shopping from '@/assets/img/shopping.png';
import Notes from '@/components/Modal/Notes';
import { EditNote } from '@mui/icons-material';
import { getServingTimes } from '@/store/slices/contentSlice';
import { getCutOffTime } from '@/helper/getCutOffTime';
import getRemainingTime from '@/helper/getRemainingTime';
import { wTextST } from '@/constants/wTextST';
import WarningServingTime from '@/components/Modal/WarningServingTime';

export default function Cart() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const innerHeight = useSelector((state) => state.content.innerHeight);
	const cartItems = useSelector((state) => state.cart.items);
	const cart = useSelector((state) => state.cart);
	const selectedOutlet = useSelector((state) => state.auth.selectedOutlet);
	const payType = useSelector((state) => state.content.payType);
	const selectedCustomer = useSelector((state) => state.auth.selectedCustomer);
	const discountCustomer = useSelector((state) => state.cart.discount);
	const utcOffset = useSelector((state) => state.content.utcOffset);
	const outlet = useSelector((state) => state.content.outlet);

	const [openNotes, setOpenNotes] = useState(false);
	const [showWarning, setShowWarning] = useState(false);

	const totalDepositAfterOrder = cartItems?.length > 0 ? (+selectedCustomer.balance || 0) - (cart.total + cart.totalAddOns) : 0;
	const totalOrderHitThreshold = cartItems?.length > 0 && Math.sign(totalDepositAfterOrder) === -1;
	const disableOrder = outlet?.use_minimum_balance_limit && totalDepositAfterOrder < +outlet.minimum_balance_limit;

	const {
		mutate: saveOrders,
		isSuccess,
		isPending,
	} = usePatch({
		url: `point-of-sale/orders/process_bulk`,
		method: 'post',
		callback: () => {
			dispatch(getCustomer({ params: {}, id: selectedCustomer.id }));
			toast.success('Order has been paid successfully');
			navigate('/');
			dispatch(getServingTimes({ outlet: selectedOutlet?.id, max_size: true }));
			setTimeout(() => {
				dispatch({ type: 'cart/resetItems', payload: true });
			}, 300);
		},
		onError: () => {
			toast.error('There is an Error with order');
		},
		showError: true,
	});

	const items = useMemo(() => {
		return cartItems.length
			? cartItems.reduce((group, product) => {
					const { date } = product;
					const dateKey = dayjs(date).format('YYYY-MM-DD');
					group[dateKey] = group[dateKey] ?? [];
					group[dateKey].push(product);
					return group;
			  }, {})
			: [];
	}, [cartItems]);

	const saveTheOrder = async () => {
		// if (selectedCustomer || selectedOutlet) {
		// 	navigate(`/${route.notification}`);
		// 	return
		// }
		if (disableOrder) return;
		const orderData = Object.keys(items).map((d) => {
			const pItems = items[d];
			const objOrder = {
				outlet: selectedOutlet.id,
				customer: selectedCustomer.id,
				note: items[d][0]?.notes || '',
				date: d,
				products: pItems.map((p) => {
					const objP = { product: p.id, quantity: p.quantity, is_void: p.is_void };
					if (p.add_ons?.length) {
						objP.add_ons = p.add_ons.map((m) => {
							return m.add_on.id;
						});
					}
					if (p.modifiers?.length) {
						objP.modifiers = p.modifiers.map((m) => {
							return m.modifier.id;
						});
					}
					objP.serving_time = p.serving_time?.id || null;
					if (p.discount?.type) {
						objP.discount = +p.discount?.value || 0;
						objP.discount_type = p.discount?.type || null;
					}
					const { isDisable } = getSTime(p.serving_time, dayjs(p.date));
					objP.isValid = isDisable;
					return objP;
				}),
				payment_type: payType.deposit,
				type: 'Customer App',
			};
			return objOrder;
		});

		const checkIfOrderTimeIsExpired = orderData.some((d) => d.products.some((p) => 'isValid' in p && !!p.isValid));
		if (checkIfOrderTimeIsExpired) {
			setShowWarning(true);
			return;
		}

		await saveOrders(orderData);
	};

	const changeAmount = (type, item) => {
		dispatch({ type: 'cart/setItemQuantity', payload: { type, item } });
	};
	const getSTime = (s, orderDate) => {
		const { oCutOff, sT } = getCutOffTime(s, orderDate, utcOffset);
		const remainingDayTime = getRemainingTime(oCutOff);
		let isDisable = remainingDayTime < 0 ? true : false;

		return { isDisable, oCutOff, sT };
	};

	return (
		<div style={{ minHeight: innerHeight }} className='app-container'>
			<div className='relative flex flex-col w-full h-full gap-4 p-4 pb-24'>
				<UserHeader />
				{cartItems?.length > 0 && (
					<div className='flex flex-col gap-2 mb-4'>
						{Object.keys(items).map((k) => {
							const { valInlineDiscount, subT, valModifiers } = getEverything(items[k], discountCustomer);

							const total = subT + valModifiers;
							const totalDiscount = valInlineDiscount;
							const subTotal = total - totalDiscount;
							const notes = items[k][0]?.notes || '';
							const cItems = items[k].map((item) => {
								let discItem = item.discount ? generateDiscountValue(item, false) : 0;
								const showDiscItem = discItem.disc > 0;
								const { isDisable } = getSTime(item.serving_time, dayjs(item.date));
								return (
									<div key={item.uid} className='flex flex-col gap-2'>
										<LabelCartItem item={item} value1={`${item.quantity}x`} value2={item.name} value3={item.selling_price} />
										{item.add_ons?.map((a) => {
											return (
												<LabelCartItemChild key={a.id} label={a.add_on.name} value={a.add_on.price ? `${nf(a.add_on.price)}` : 'Free'} />
											);
										})}
										{item.modifiers?.map((a) => {
											return <LabelCartItemChild key={a.id} label={a.modifier.name} value='Free' />;
										})}
										{discountCustomer && showDiscItem && (
											<LabelCartItemAdd
												label={`disc (${nf(discItem.disc)}) ${discountCustomer?.name ? `- ${discountCustomer.name}` : ''}`}
												value=''
											/>
										)}
										<LabelCartItemAdd label='Serving Time' value={item.serving_time?.name} />

										<div className='carts items'>
											<span />
											<Link
												onClick={() => {
													dispatch({ type: 'cart/setSelectedItem', payload: item });
												}}
												to={`/${route.cartItem}/${item.uid}/`}
												className='px-2 text-left border border-black rounded-lg w-fit'
											>
												Edit
											</Link>
											<QuantityControl
												item={item}
												amount={item.quantity}
												changeAmount={changeAmount}
												config={{ className: 'justify-end' }}
											/>
										</div>
										<div className='carts items'>
											<span />
										</div>
										{isDisable && <span className='text-red-300'>{wTextST}</span>}
									</div>
								);
							});
							const date = dayjs(k).format('dddd, DD MMMM YYYY');
							return (
								<div key={k} className='flex flex-col gap-2'>
									<span className='font-bold'>{date}</span>
									<div className='flex flex-col gap-4'>{cItems}</div>
									<LabelNotes notes={notes}>
										<button
											onClick={() => setOpenNotes({ date: items[k][0].date, notes })}
											className='flex items-center justify-center gap-1 px-2 text-left border border-black rounded-lg w-fit'
										>
											Notes
											<EditNote />
										</button>
									</LabelNotes>
									{discountCustomer && totalDiscount > 0 && (
										<>
											<LabelCartItemAdd label='Total' value={nf(total || 0)} />
											<LabelCartItemAdd label='Discount' value={`-${nf(totalDiscount || 0)}`} />
										</>
									)}
									<LabelCartItemAdd label='Sub Total' value={nf(subTotal || 0)} />
								</div>
							);
						})}
					</div>
				)}
				{isSuccess || (isPending && <Loader />)}
				{totalOrderHitThreshold && (
					<span>
						Sorry, your deposit is insufficient. After your recent order your remaining balance is{' '}
						<span className='text-red-500'>{nf((+selectedCustomer.balance || 0) - (cart.total + cart.totalAddOns))}</span>. Please contact
						our admin for your top up. Kind regards Jungle Toppings
					</span>
				)}
				{cartItems?.length > 0 && (
					<button className='btn-actions' onClick={saveTheOrder} disabled={disableOrder}>
						Purchase <span className='ml-1'>{nf(cart.total + cart.totalAddOns)}</span>
					</button>
				)}
				{cartItems?.length === 0 && (
					<div className='flex flex-col items-center justify-center m-auto'>
						<div className='mx-auto text-center'>
							<img src={shopping} className='w-auto h-36' alt='Empty icons created by Roundicons Premium - Flaticon' />
						</div>
						<span>Your cart is empty</span>
					</div>
				)}
			</div>
			<Navigation />
			{!!openNotes && <Notes open={!!openNotes} onClose={() => setOpenNotes(false)} data={openNotes} />}
			<WarningServingTime
				open={showWarning}
				onClose={() => setShowWarning(false)}
				from='cart'
				onClearCart={() => {
					setShowWarning(false);
					dispatch({ type: 'cart/resetItems', payload: true });
				}}
			/>
		</div>
	);
}
