import axiosInstances from '@/helper/axiosInstances';
import qs from 'qs';

const baseURL = import.meta.env.VITE_API_URL;

export default function customers() {
	const { axiosInstance } = axiosInstances();

	async function getCustomers(params, customerId = null) {
		const method = 'get';
		let url = `${baseURL}point-of-sale/customers/`;
		if (customerId) url += customerId

		const response = await axiosInstance({
			method,
			url,
			params,
		});
		return response;
	}
	async function getTopUpsCustomer(params) {
		const method = 'get';
		let url = `${baseURL}point-of-sale/orders/`;

		const response = await axiosInstance({
			method,
			url,
			params,
			paramsSerializer: (params) => {
				return qs.stringify(params, { arrayFormat: 'comma', encode: false});
			},
		});
		return response;
	}
	async function getDiscountCustomer(params) {
		const method = 'get';
		let url = `${baseURL}point-of-sale/discounts/get_by_customer/`;

		const response = await axiosInstance({
			method,
			url,
			params
		});
		return response;
	}

	return { getCustomers, getTopUpsCustomer, getDiscountCustomer };
}
